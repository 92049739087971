import "./hero.css";
import image from "../assets/hero_image_tablet.jpg";

export default function Hero() {
  return (
    <div className="hero-actis-tablet bg-primary">
      <img src={image} alt="Hero" className="image-hero-actis-tablet" />
      <div className="text-box-hero-actis-tablet text-white">
        <h2 className="h3-m">Actis (Active Sensing)</h2>
        <p className="text-lg-m text-hero-actis-tablet">
          An innovative method for locating pre-faults, local anomalies, and
          disturbances in both power networks and water/gas pipelines.
        </p>
      </div>
    </div>
  );
}
