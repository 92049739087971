import NavBar from '../../components/desktop/NavBar';
import Footer from '../../components/desktop/Footer';
import Hero from './Hero';
import Products from './Products';
import Table from './Table';
import Commitment from './Commitment';
import Contact from './Contact';

export default function ProductsPowerDesktop() {
  return (
    <div>
      <NavBar products />
      <Hero />
      <Products />
      <Table />
      <Commitment />
      <Contact />
      <Footer />
    </div>
  );
}
