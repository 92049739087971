import './support.css';
import logo1 from '../assets/logo_1.png';
import logo2 from '../assets/logo_2.png';
import logo3 from '../assets/logo_3.png';
import logo4 from '../assets/logo_4.png';
import logo5 from '../assets/logo_5.png';
import logo6 from '../assets/logo_6.png';

export default function Support() {
  return (
    <div className='support-mobile'>
      <div className='support-title-mobile'>
        <h3 className='h5-s'>With the support of</h3>
        <div className='support-divider-mobile' />
      </div>
      <div className='logos-container-mobile'>
        <img src={logo1} className='logo-image-1-mobile' />
        <img src={logo2} className='logo-image-2-mobile' />
      </div>
      <div className='logos-container-mobile'>
        <img src={logo5} className='logo-image-5-mobile' />
        <img src={logo3} className='logo-image-3-mobile' />
      </div>
      <div className='logos-container-mobile'>
        <img src={logo4} className='logo-image-4-mobile' />
        <img src={logo6} className='logo-image-6-mobile' />
      </div>
    </div>
  );
}
