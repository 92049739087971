import ContactDesktop from './desktop/ContactDesktop';
import ContactTablet from './tablet/ContactTablet';
import ContactMobile from './mobile/ContactMobile';
import { useMediaQuery } from 'react-responsive';

export default function Contact() {
  const isMobileDevice = useMediaQuery({
    query: '(min-width: 320px)',
  });

  const isTabletDevice = useMediaQuery({
    query: '(min-width: 768px)',
  });

  const isDesktopDevice = useMediaQuery({
    query: '(min-width: 1024px)',
  });

  return (
    <div>
      {isDesktopDevice ? (
        <ContactDesktop />
      ) : isTabletDevice ? (
        <ContactTablet />
      ) : (
        <ContactMobile />
      )}
    </div>
  );
}
