import './step_1.css';
import figure_4 from '../assets/antenna.mp4';

export default function Step1() {
  return (
    <div className='step-1-desktop'>
      <div className='step-1-text-desktop bg-primary text-white'>
        <div className='step-1-title-desktop'>
          <p className='h3'>1.</p>
          <p className='h4'>
            Pulse detection originating from a Partial Discharge source
          </p>
        </div>
        <p className='text-lg step-1-text-content-desktop'>
          The wideband Translocator antenna, as depicted in{' '}
          <span className='s4'>Figure 4</span>, is wrapped around the cable. It
          senses the presence of the PD, capturing its waveform as it propagates
          through the cable and reaches the sensing point.
        </p>
      </div>
      <div className='figure-4-desktop'>
        <video
          src={figure_4}
          type='video/mp4'
          autoPlay
          muted
          playsinline
          loop
          className='video-figure-4-desktop'
        />
        <p className='description-figure-4-desktop s5 text-gray-80'>
          Figure 4 - Electromagnetic sensing of transient phenomena
        </p>
      </div>
    </div>
  );
}
