import "./solution.css";
import levels from "../assets/levels_mobile.svg";
import steps from "../assets/steps_mobile.svg";
import arrow_right from "../assets/arrow_right_light_blue.svg";

export default function Solution() {
  return (
    <div className="solution-mobile">
      <h2 className="h4-s">The solution we offer</h2>
      <div className="solution-content-mobile">
        <p className="text-reg-s">
          <span className="h6-s">Altrans Energies</span> offers a complete
          solution for monitoring networks and pipelines, regardless of their
          size and purpose within the water/gas/power infrastructure. The system
          can be installed at any of the following levels:
        </p>
        <img src={levels} className="solution-levels-img-mobile" />
        <p className="text-reg-s">
          For an efficient monitoring system, our systems provide four main
          steps, as illustrated below;
        </p>
        <img src={steps} className="solution-steps-img-mobile" />
        <p className="text-reg-s">
          <span className="s5-s">The sensing</span> is adapted to the network's
          type and can operate in either the acoustic or electromagnetic range.
          <span className="s5-s">The localization</span> process uses advanced
          signal processing techniques to precisely pinpoint the location of
          prefaults. Machine Learning algorithms, based on novel
          characterizations of the monitored phenomena, handle{" "}
          <span className="s5-s">the classification</span> task. Finally, we
          employ continuous network monitoring for{" "}
          <span className="s5-s">risk assessment</span>.
        </p>
        <a className="read-more-mobile" href="/general-approach">
          <div className="text-primary text-read-more-mobile">
            <p className="h6-s no-line-height-mobile">
              See our general approach
            </p>
            <img src={arrow_right} className="arrow-read-more-mobile" />
          </div>
          <div className="line-read-more-mobile"></div>
        </a>
      </div>
    </div>
  );
}
