import AboutDesktop from './desktop/AboutDesktop';
import AboutTablet from './tablet/AboutTablet';
import AboutMobile from './mobile/AboutMobile';
import { useMediaQuery } from 'react-responsive';

export default function About() {
  const isMobileDevice = useMediaQuery({
    query: '(min-width: 320px)',
  });

  const isTabletDevice = useMediaQuery({
    query: '(min-width: 768px)',
  });

  const isDesktopDevice = useMediaQuery({
    query: '(min-width: 1024px)',
  });

  return (
    <div>
      {isDesktopDevice ? (
        <AboutDesktop />
      ) : isTabletDevice ? (
        <AboutTablet />
      ) : (
        <AboutMobile />
      )}
    </div>
  );
}
