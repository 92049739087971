import "./content.css";
import actis_electric from "../assets/actis_electric.mp4";
import actis_water_gas from "../assets/actis_water_gas.mp4";

export default function Content() {
  return (
    <div className="actis-content-tablet">
      <p className="text-lg-m">
        The active sensing developed by{" "}
        <span className="h5-m">Altrans Energies</span> is a concept that
        exploits the propagation of a known emitted waveform. The physics of
        wave propagation through dispersive media form the foundation for a
        powerful approach to identifying and localizing instabilities,
        anomalies, and pre-faults in power grids and pipelines.
      </p>
      <div className="figure-gif-actis-tablet">
        <h4 className="h5-m">Active Sensing on Power Network</h4>
        <video
          src={actis_electric}
          type="video/mp4"
          autoPlay
          muted
          playsinline
          loop
          className="video-actis-tablet"
        />
      </div>
      <div className="figure-gif-actis-tablet">
        <h4 className="h5-m">Active Sensing on Water/Gas Pipelines</h4>
        <video
          src={actis_water_gas}
          type="video/mp4"
          autoPlay
          muted
          playsinline
          loop
          className="video-actis-tablet"
        />
      </div>
      <p className="text-lg-m">
        The active sensing proposed by us takes into account the interactions
        among phenomena of interest (such as partial discharges, electrical
        arcs, leaks, vibrations, external sources, system control and command
        signals etc.) and other signals, whether naturally occurring or
        artificially injected inside the grids. A data-driven approach to signal
        processing and interpretation allows us to identify, localize, and
        quantify these phenomena.
      </p>
    </div>
  );
}
