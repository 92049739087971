import "./hero.css";
import image from "../assets/hero_image_tablet.jpg";

export default function Hero() {
  return (
    <div className="hero-general-approach-tablet bg-primary">
      <img
        src={image}
        alt="Hero"
        className="image-hero-general-approach-tablet"
      />
      <div className="text-box-hero-general-approach-tablet text-white">
        <h2 className="h3-m">General approach</h2>
        <p className="text-lg-m text-hero-general-approach-tablet">
          Our complete solution for monitoring networks and pipelines consists
          of four main steps, which are presented below.
        </p>
      </div>
    </div>
  );
}
