import "./localization.css";

export default function Localization() {
  return (
    <div className="general-approach-localization-desktop">
      <h2 className="h2 text-primary">Localization</h2>
      <div className="localization-joint-content-desktop">
        <li className="text-lg">
          Synchronization-free approach: no need for GPS to synchronize the
          systems.
        </li>
      </div>
      <div className="localization-content-desktop">
        <ul className="localization-list-desktop">
          <li className="text-lg">
            Based on passive sensing exploiting the Translocator© patent.
          </li>
          <li className="text-lg">
            Active sensing uses RODECIN©, a concept that exploits the
            propagation of a known emitted waveform.
          </li>
        </ul>
        <div className="localization-element-divider-desktop" />
        <ul className="localization-list-desktop">
          <li className="text-lg">
            Based on passive and active sensing techniques, the localization
            procedure takes into account the interactions between the phenomena
            of interest (leaks, system control and command signals, vibrations,
            external sources) and other signals occurring naturally or
            physically inside the pipes.
          </li>
        </ul>
      </div>
    </div>
  );
}
