import './hero.css';
import image from '../assets/hero_image_tablet.jpg';

export default function Hero() {
  return (
    <div className='hero-translocator-tablet bg-primary'>
      <img src={image} alt='Hero' className='image-hero-translocator-tablet' />
      <div className='text-box-hero-translocator-tablet text-white'>
        <h2 className='h3-m'>Translocator Patent</h2>
        <p className='text-lg-m text-hero-translocator-tablet'>
          A method for locating a source of pulses in a dispersive medium using
          at least one pair of sensors.
        </p>
      </div>
    </div>
  );
}
