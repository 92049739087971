import "./content.css";

export default function Content() {
  return (
    <div className="legal-mentions-content-mobile">
      <div className="legal-mentions-section-mobile">
        <h3 className="s5-s">Terms of Use:</h3>
        <p className="text-reg-s">
          Welcome to the Altrans Energies website. By accessing and using this
          website, you agree to comply with and be bound by the following terms
          and conditions of use. If you disagree with any part of these terms,
          please do not use our website.
        </p>
      </div>
      <div className="legal-mentions-section-mobile">
        <h3 className="s5-s">Use of Cookies:</h3>
        <p className="text-reg-s">We do not use cookies on this website.</p>
      </div>
      <div className="legal-mentions-section-mobile">
        <h3 className="s5-s">Collection of Personal Data:</h3>
        <p className="text-reg-s">
          We do not collect any personal data from our users. Personal data
          refers to information that identifies or can be used to identify an
          individual, such as names, email addresses, phone numbers, or any
          other information that could reasonably be linked to such identifiers.
        </p>
      </div>
      <div className="legal-mentions-section-mobile">
        <h3 className="s5-s">Information Automatically Collected:</h3>
        <p className="text-reg-s">
          Our website may automatically collect certain non-personal
          information, such as the type of browser you are using or your IP
          address. This information is used solely for statistical purposes and
          does not identify individual users.
        </p>
      </div>
      <div className="legal-mentions-section-mobile">
        <h3 className="s5-s">Security Measures:</h3>
        <p className="text-reg-s">
          While we do not collect personal data, we take reasonable measures to
          ensure the security of our website. However, please be aware that no
          internet transmission is completely secure, and we cannot guarantee
          the security of information transmitted to or from our website.
        </p>
      </div>
      <div className="legal-mentions-section-mobile">
        <h3 className="s5-s">Disclaimer:</h3>
        <p className="text-reg-s">
          The information provided on this website is for general informational
          purposes only. We make no representations or warranties of any kind,
          express or implied, about the completeness, accuracy, reliability,
          suitability, or availability with respect to the website or the
          information, products, services, or related graphics contained on the
          website for any purpose.
        </p>
      </div>
      <div className="legal-mentions-section-mobile">
        <h3 className="s5-s">Intellectual Property:</h3>
        <p className="text-reg-s">
          All content on this website, including but not limited to text,
          graphics, logos, images, audio clips, and software, is the property of
          Altrans Energies and is protected by copyright and other intellectual
          property laws. You may not reproduce, distribute, display, or create
          derivative works of any content without our prior written permission.
        </p>
      </div>
      <div className="legal-mentions-section-mobile">
        <h3 className="s5-s">Limitation of Liability:</h3>
        <p className="text-reg-s">
          In no event will Altrans Energies be liable for any loss or damage,
          including without limitation, indirect or consequential loss or
          damage, or any loss or damage whatsoever arising from loss of data or
          profits arising out of, or in connection with, the use of this
          website.
        </p>
      </div>
      <div className="legal-mentions-section-mobile">
        <h3 className="s5-s">Links to Other Websites:</h3>
        <p className="text-reg-s">
          Our website may contain links to third-party websites. These links are
          provided for your convenience and do not signify that we endorse the
          website(s). We have no responsibility for the content of linked
          website(s).
        </p>
      </div>
    </div>
  );
}
