import ActisDesktop from "./desktop/ActisDesktop";
import ActisTablet from "./tablet/ActisTablet";
import ActisMobile from "./mobile/ActisMobile";
import { useMediaQuery } from "react-responsive";

export default function Translocator() {
  const isMobileDevice = useMediaQuery({
    query: "(min-width: 320px)",
  });

  const isTabletDevice = useMediaQuery({
    query: "(min-width: 768px)",
  });

  const isDesktopDevice = useMediaQuery({
    query: "(min-width: 1024px)",
  });

  return (
    <div>
      {isDesktopDevice ? (
        <ActisDesktop />
      ) : isTabletDevice ? (
        <ActisTablet />
      ) : (
        <ActisMobile />
      )}
    </div>
  );
}
