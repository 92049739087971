import NavBar from '../../components/mobile/NavBar';
import Footer from '../../components/mobile/Footer';
import Hero from './Hero';
import Content from './Content';

export default function ContactMobile() {
  return (
    <div>
      <NavBar contact />
      <Hero />
      <Content />
      <Footer />
    </div>
  );
}
