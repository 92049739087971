import "./why_part_1.css";
import figure_1 from "../assets/figure_1.svg";

export default function WhyPart1() {
  return (
    <div className="why-part-1-desktop">
      <h2 className="h2">Why is it useful?</h2>
      <div className="content-part-1-desktop">
        <p className="text-lg">
          In transport lines, such as those for electric power, water, or data,
          various phenomena may lead to the generation of transients (pulses) at
          specific points in the lines. It is desirable to detect and locate the
          area in which these pulses are generated.
        </p>
        <div className="content-figure-1-desktop">
          <div className="text-figure-1-desktop">
            <p className="text-lg">
              The most important phenomena that serve as sources of transient
              signals are partial discharges, electrical arcs, and impedance
              mismatching. There are various reasons for their occurrence, some
              of which are presented in <span className="s4">Figure 1</span>.
            </p>
            <p className="text-lg">Here, we include:</p>
            <ul className="list-figure-1-desktop">
              <li className="text-lg">
                Mechanical degradations resulting from careless cable handling,
                especially during installation in the network.
              </li>
              <li className="text-lg">
                Aging phenomena that make the infrastructure critical.
              </li>
              <li className="text-lg">
                Misuse of the cables, such as overloading their capacity,
                especially in today's high-energy-demand scenario
              </li>
              <li className="text-lg">
                Manufacturing imperfections, encompassing problems that the
                cables may have even before being put into service, rendering
                them more susceptible to faults.
              </li>
            </ul>
          </div>
          <div className="figure-1-desktop">
            <img src={figure_1} className="image-figure-1-desktop" />
            <p className="description-figure-1-desktop s5 text-gray-80">
              Figure 1 - Examples of cable issues and transient source phenomena
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
