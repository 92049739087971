import './use_cases.css';
import use_case_electric from '../assets/use_case_electric.png';
import use_case_water_gas from '../assets/use_case_water_gas.png';
import arrow_right from '../assets/arrow_right_gray.svg';

export default function UseCases() {
  return (
    <div className='use-cases'>
      <h3 className='h3'>Multiple Operational Scenarios </h3>
      <div className='use-cases-container'>
        <div className='card-use-cases'>
          <img src={use_case_electric} className='use-case-image' />
          <div className='text-use-cases electric-use-case'>
            <p className='s1 text-white'>
              For <span className='h2'>Power Grids</span>
            </p>
            <a
              className='read-more more-products-color'
              href='/products/power-grids'
            >
              <div className='text-read-more'>
                <p className='h5 no-line-height'>See products</p>
                <img src={arrow_right} className='arrow-read-more ' />
              </div>
              <div className='line-read-more more-products-color-line'></div>
            </a>
          </div>
        </div>
        <div className='card-use-cases'>
          <img src={use_case_water_gas} className='use-case-image' />
          <div className='text-use-cases water-gas-use-case'>
            <p className='s1 text-white water-gas-text'>
              For <span className='h2'>Water/Gas Networks</span>
            </p>
            <a
              className='read-more more-products-color'
              href='/products/water-gas-networks'
            >
              <div className='text-read-more'>
                <p className='h5 no-line-height'>See products</p>
                <img src={arrow_right} className='arrow-read-more ' />
              </div>
              <div className='line-read-more more-products-color-line'></div>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}
