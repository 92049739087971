import './team.css';
import cornel from '../assets/cornel.png';
import angela from '../assets/angela.png';

export default function Team() {
  return (
    <div className='team-mobile'>
      <h2 className='h4-s'>Our team</h2>
      <div className='team-member-mobile'>
        <img src={cornel} className='team-member-image-mobile' />
        <div className='team-member-description-mobile'>
          <div className='team-member-title-mobile'>
            <h2 className='h4-s'>Cornel Ioana</h2>
            <h3 className='s4-s text-gray-80'>President and CEO</h3>
          </div>
          <ul className='team-member-achivements-mobile'>
            <li className='text-reg-s member-achivement-mobile'>
              +45 international papers.
            </li>
            <li className='text-reg-s member-achivement-mobile'>
              7 patent filed as inventor.
            </li>
            <li className='text-reg-s member-achivement-mobile'>
              3 deeptech start-ups created.
            </li>
            <li className='text-reg-s member-achivement-mobile'>
              Habilitation to supervise research activities at the University of
              Grenoble Alpes - Electronique, Electrotechnique, Automatique et
              Traitement du Signal, November 28, 2012.
            </li>
            <li className='text-reg-s member-achivement-mobile'>
              PhD degree (Électronique-Traitement du Signal), University of
              Brest, defended on 19 September 2003
            </li>
          </ul>
        </div>
      </div>
      <div className='team-member-mobile'>
        <img src={angela} className='team-member-image-mobile' />
        <div className='team-member-description-mobile'>
          <div className='team-member-title-mobile'>
            <h2 className='h4-s'>Angela Digulescu</h2>
            <h3 className='s4-s text-gray-80'>CTO</h3>
          </div>
          <ul className='team-member-achivements-mobile'>
            <li className='text-reg-s member-achivement-mobile'>
              +30 international papers.
            </li>
            <li className='text-reg-s member-achivement-mobile'>
              Expert in the fields of non-stationary and transient signal
              analysis.
            </li>
            <li className='text-reg-s member-achivement-mobile'>
              PhD degree (EEATS doctoral school), University of Grenoble Alpes,
              defended on 17 january 2017.
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
}
