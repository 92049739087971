import "./hero.css";
import { useNavigate } from "react-router-dom";
import image from "../assets/hero_image_mobile.jpg";

export default function Hero() {
  let navigate = useNavigate();
  const routeChange = () => {
    let path = `/index`;
    navigate(path);
  };

  return (
    <div className="hero-mobile bg-primary">
      <img src={image} alt="Hero" className="image-hero-mobile" />
      <div className="text-box-hero-mobile text-white">
        <div className="title-box-hero-mobile">
          <h2 className="h4-s title-hero-mobile">
            Empowering tomorrow's energy grids with online and precise early
            fault detection
          </h2>
          <p className="s4-s">
            for <span className="h5-s">Electrical</span>,{" "}
            <span className="h5-s">Gas</span>, and{" "}
            <span className="h5-s">Water</span> Infrastructure Monitoring
          </p>
        </div>
        <p className="text-reg-s text-hero-mobile">
          <span className="h6-s">Altrans Energies</span> pioneers advanced
          solutions in global and predictive monitoring seamlessly integrating
          innovative sensing and quantum-inspired AI algorithms to enhance the
          resilience of grids and to reduce their maintenance cost.
        </p>
        <button
          className="btn btn-secondary text-primary h6-s button-hero-mobile"
          onClick={routeChange}
        >
          Contact Us
        </button>
      </div>
    </div>
  );
}
