import NavBar from "../../components/tablet/NavBar";
import Footer from "../../components/tablet/Footer";
import Hero from "./Hero";
import Content from "./Content";

export default function ActisTablet() {
  return (
    <div>
      <NavBar innovation />
      <Hero />
      <Content />
      <Footer />
    </div>
  );
}
