import "./why_part_3.css";
import figure_3 from "../assets/translocator_formula.png";

export default function WhyPart3() {
  return (
    <div className="why-part-3-desktop">
      <div className="content-part-3-desktop">
        <p className="text-lg">
          An example of transient source localization is depicted in{" "}
          <span className="s4">Figure 3</span>. Locating the pulse source allows
          for the identification of the fault, facilitating the replacement or
          repair of the cable section affected. Errors in estimating the exact
          position of the pulse source may lead to taking action on the wrong
          cable portion and necessitate repeating the maintenance operation.
        </p>
        <div className="figure-3-desktop">
          <img src={figure_3} className="image-figure-3-desktop" />
          <p className="description-figure-3-desktop s5 text-gray-80">
            Figure 3 - Localization of the transient source using TRANSLOCATOR
            patent
          </p>
        </div>
      </div>
      <h2 className="h2">Steps</h2>
    </div>
  );
}
