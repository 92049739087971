import './awards.css';
import image from '../assets/awards_collage.png';

export default function Awards() {
  return (
    <div className='awards-desktop'>
      <div className='bg-primary text-white awards-content-desktop'>
        <div className='awards-text-desktop'>
          <h2 className='h2'>Awards</h2>
          <ul className='awards-list-desktop'>
            <li className='text-lg awards-list-item-desktop'>
              Winner of the <span className='h5'>Paris Urban Lab 2023</span>{' '}
              program.
            </li>
            <li className='text-lg awards-list-item-desktop'>
              Labeled <span className='h5'>Greentech Innovation 2022</span> by
              the Ministry of Ecological Transition, recognizing our commitment
              to reducing the carbon footprint of electrical network maintenance
              operations.
            </li>
            <li className='text-lg awards-list-item-desktop'>
              Winner of the <span className='h5'>NETVA 2022</span> program -
              France-United States Collaboration organized by BPI and the
              Embassy of France in the United States.
            </li>
            <li className='text-lg awards-list-item-desktop'>
              Public Grand Prize in the{' '}
              <span className='h5'>
                "Trophées de la Simulation et des technologies numériques 2022"
              </span>{' '}
              competition organized by L’Usine Nouvelle.
            </li>
            <li className='text-lg awards-list-item-desktop'>
              Winner of the <span className='h5'>Innotrophées 2022</span> Award
              organized by the CCI of Grenoble, start-up category.
            </li>
            <li className='text-lg awards-list-item-desktop'>
              Winner of the <span className='h5'>I-lab 2021</span> with the
              GOLTEN project.
            </li>
            <li className='text-lg awards-list-item-desktop'>
              Labeled <span className='h5'>DeepTech in November 2020</span>.
            </li>
            <li className='text-lg awards-list-item-desktop'>
              Winner of the{' '}
              <span className='h5'>Grid’Up Prize by ENEDIS in 2017</span>.
            </li>
          </ul>
        </div>
        <img src={image} className='awards-image-desktop' />
      </div>
    </div>
  );
}
