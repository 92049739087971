import "./hero.css";
import image from "../assets/hero_image.jpg";

export default function Hero() {
  return (
    <div className="hero-legal-mentions-desktop bg-primary">
      <img
        src={image}
        alt="Hero"
        className="image-hero-legal-mentions-desktop"
      />
      <div className="text-box-hero-legal-mentions-desktop text-white">
        <h2 className="h2">Legal Mentions</h2>
      </div>
    </div>
  );
}
