import "./news.css";
import { useState } from "react";
import Carousel from "react-bootstrap/Carousel";
import img1 from "../assets/carnot2023.jpg";
import video1 from "../assets/carnot2023_vid.mp4";
import img2 from "../assets/transrail.jfif";
import img3 from "../assets/creid_1.jpg";
import img4 from "../assets/lyon_system.png";
import img5 from "../assets/lyon_system_people.jpg";
import img6 from "../assets/angela_NETVA.jpg";
import img7 from "../assets/20221012_162126.jpg";
import img8 from "../assets/altrans_energiescf.ardito.jpg";
import img9 from "../assets/afrique_france_forum.jpeg";
import img10 from "../assets/innotrophees_1.png";
import img11 from "../assets/innotrophees_2.png";
import img12 from "../assets/Trophee14Juin2022.jpeg";
import img13 from "../assets/singapore.jpg";
import img14 from "../assets/pen_size.png";

export default function News() {
  const [index, setIndex] = useState(0);
  const [indexPost, setIndexPost] = useState(0);

  const handleSelect = (selectedIndex) => {
    setIndex(selectedIndex);
  };

  const handleSelectPost = (selectedIndex) => {
    setIndexPost(selectedIndex);
  };

  return (
    <div className="posts-container-tablet bg-primary">
      <div className="news-text-tablet text-white">
        <h2 className="h3-m">Our latest news</h2>
        <div className="news-paragprah-tablet">
          <p className="text-lg-m">
            Here you can find a glimpse into the latest news and remarkable
            achievements of <span className="h5-m">ALTRANS ENERGIES</span> ,
            showcasing our ongoing innovations, breakthroughs, and contributions
            to the energy sector.
          </p>
          <p className="text-lg-m">
            You can also connect with us on our{" "}
            <a
              className="s4-m text-white"
              href={"https://www.linkedin.com/company/altransinnov/"}
            >
              <u>LinkedIn</u>
            </a>{" "}
            page.
          </p>
          <p className="text-lg-m">
            For more scientifical insights take a look at{" "}
            <a
              className="s4-m text-white"
              href={"https://www.linkedin.com/company/altransinnov/"}
            >
              <u>our research papers and published results</u>
            </a>
            .
          </p>
        </div>
      </div>
      <Carousel
        controls={true}
        indicators={false}
        interval={null}
        slide={true}
        activeIndex={index}
        onSelect={handleSelect}
        className="posts-carousel-tablet text-center"
      >
        <Carousel.Item>
          <div className="post-tablet bg-white">
            <p className="h6-m text-primary post-date-tablet">4th april 2024</p>
            <Carousel
              controls={false}
              indicators={false}
              interval={null}
              slide={true}
              activeIndex={indexPost}
              onSelect={handleSelectPost}
              className="post-image-tablet"
            >
              <Carousel.Item>
                <img src={img14} className="car-img-tablet" />
              </Carousel.Item>
            </Carousel>
            <p className="s3-m post-title-tablet">
              New Translocator Packaging Design
            </p>
            <p className="text-lg-m post-description-tablet">
              Our new general-purpose sensing unit is now available! Looking
              forward to proposing it to our partners!
            </p>
          </div>
        </Carousel.Item>
        <Carousel.Item>
          <div className="post-tablet bg-white">
            <p className="h6-m text-primary post-date-tablet">
              25th october 2023
            </p>
            <Carousel
              controls={false}
              indicators={false}
              interval={null}
              slide={true}
              activeIndex={indexPost}
              onSelect={handleSelectPost}
              className="post-image-tablet"
            >
              <Carousel.Item>
                <img src={img13} className="car-img-tablet" />
              </Carousel.Item>
            </Carousel>
            <p className="s3-m post-title-tablet">
              Participation of ALTRANS Energies to iPitch@ ACES TechTalk
            </p>
            <p className="text-lg-m post-description-tablet">
              Meeting with Singapore’s Minister of State for the Ministry of
              Trade and Industry (MTI) – Ms Low Yen Ling.
            </p>
          </div>
        </Carousel.Item>
        <Carousel.Item>
          <div className="post-tablet bg-white">
            <p className="h6-m text-primary post-date-tablet">
              18 - 19 october 2023
            </p>
            <Carousel
              controls={false}
              indicators={true}
              interval={null}
              slide={true}
              activeIndex={indexPost}
              onSelect={handleSelectPost}
              className="post-image-tablet"
            >
              <Carousel.Item>
                <img src={img1} className="car-img-tablet" />
              </Carousel.Item>
              <Carousel.Item>
                <video
                  src={video1}
                  type="video/mp4"
                  autoPlay
                  muted
                  playsinline
                  loop
                  className="car-img-tablet"
                />
              </Carousel.Item>
            </Carousel>
            <p className="s3-m post-title-tablet">
              Glad to attend RDV Carnot 2023
            </p>
            <p className="text-lg-m post-description-tablet">
              We are very thankful to UGA-Grenoble INP and Carnot LSI Institute
              for their precious support!
            </p>
          </div>
        </Carousel.Item>
        <Carousel.Item>
          <div className="post-tablet bg-white">
            <p className="h6-m text-primary post-date-tablet">
              27 - 28 September 2023
            </p>
            <Carousel
              controls={false}
              indicators={false}
              interval={null}
              slide={true}
              activeIndex={indexPost}
              onSelect={handleSelectPost}
              className="post-image-tablet"
            >
              <Carousel.Item>
                <img src={img2} className="car-img-zoom-tablet" />
              </Carousel.Item>
            </Carousel>
            <p className="s3-m post-title-tablet">TransRail 2023</p>
            <p className="text-lg-m post-description-tablet">
              We are very pleased to show our technology to the #TransRail
              connection 2023 in Paris, France!
            </p>
          </div>
        </Carousel.Item>
        <Carousel.Item>
          <div className="post-tablet bg-white">
            <p className="h6-m text-primary post-date-tablet">
              12 - 15 June 2023
            </p>
            <Carousel
              controls={false}
              indicators={false}
              interval={null}
              slide={true}
              activeIndex={indexPost}
              onSelect={handleSelectPost}
              className="post-image-tablet"
            >
              <Carousel.Item>
                <img src={img3} className="car-img-tablet" />
              </Carousel.Item>
            </Carousel>
            <p className="s3-m post-title-tablet">CIRED 2023</p>
            <p className="text-lg-m post-description-tablet">
              We were delighted to present ALTRANS Energies technology during
              the Exhibitors & Start-ups conference at CIRED 2023, Rome, Italy.
            </p>
          </div>
        </Carousel.Item>
        <Carousel.Item>
          <div className="post-tablet bg-white">
            <p className="h6-m text-primary post-date-tablet">
              17th March 2023
            </p>
            <Carousel
              controls={false}
              indicators={true}
              interval={null}
              slide={true}
              activeIndex={indexPost}
              onSelect={handleSelectPost}
              className="post-image-tablet"
            >
              <Carousel.Item>
                <img src={img5} className="car-img-zoom-tablet" />
              </Carousel.Item>
              <Carousel.Item>
                <img src={img4} className="car-img-tablet" />
              </Carousel.Item>
            </Carousel>
            <p className="s3-m post-title-tablet">New protoype in service!</p>
            <p className="text-lg-m post-description-tablet">
              We are thankful to ENEDIS Lyon for their great support to the
              development of our technology!
            </p>
          </div>
        </Carousel.Item>
        <Carousel.Item>
          <div className="post-tablet bg-white">
            <p className="h6-m text-primary post-date-tablet">
              10th November 2022
            </p>
            <Carousel
              controls={false}
              indicators={false}
              interval={null}
              slide={true}
              activeIndex={indexPost}
              onSelect={handleSelectPost}
              className="post-image-tablet"
            >
              <Carousel.Item>
                <img src={img6} className="car-img-tablet" />
              </Carousel.Item>
            </Carousel>
            <p className="s3-m post-title-tablet">NETVA 2022</p>
            <p className="text-lg-m post-description-tablet">
              Participation of our CTO, Angela, to the Réception Scientifique
              Français organized by the Science and Technology service of the
              Embassy of France in the United States.
            </p>
          </div>
        </Carousel.Item>
        <Carousel.Item>
          <div className="post-tablet bg-white">
            <p className="h6-m text-primary post-date-tablet">
              12-13th october 2022
            </p>
            <Carousel
              controls={false}
              indicators={false}
              interval={null}
              slide={true}
              activeIndex={indexPost}
              onSelect={handleSelectPost}
              className="post-image-tablet"
            >
              <Carousel.Item>
                <img src={img7} className="car-img-zoom-zoom-tablet" />
              </Carousel.Item>
            </Carousel>
            <p className="s3-m post-title-tablet">RDV Carnot 2022</p>
            <p className="text-lg-m post-description-tablet">
              Participants at RDV Carnot in Paris.
            </p>
          </div>
        </Carousel.Item>
        <Carousel.Item>
          <div className="post-tablet bg-white">
            <p className="h6-m text-primary post-date-tablet">
              13th october 2022
            </p>
            <Carousel
              controls={false}
              indicators={false}
              interval={null}
              slide={true}
              activeIndex={indexPost}
              onSelect={handleSelectPost}
              className="post-image-tablet"
            >
              <Carousel.Item>
                <img src={img8} className="car-img-zoom-zoom-tablet" />
              </Carousel.Item>
            </Carousel>
            <p className="s3-m post-title-tablet">Presences article</p>
            <p className="text-lg-m post-description-tablet">
              One article is talking about us in Presences.
            </p>
          </div>
        </Carousel.Item>
        <Carousel.Item>
          <div className="post-tablet bg-white">
            <p className="h6-m text-primary post-date-tablet">
              27-29 September 2022
            </p>
            <Carousel
              controls={false}
              indicators={false}
              interval={null}
              slide={true}
              activeIndex={indexPost}
              onSelect={handleSelectPost}
              className="post-image-tablet"
            >
              <Carousel.Item>
                <img src={img9} className="car-img-tablet" />
              </Carousel.Item>
            </Carousel>
            <p className="s3-m post-title-tablet">
              Forum Afrique-France de la Transition Energétique 2022
            </p>
            <p className="text-lg-m post-description-tablet">
              Participants at Forum Afrique-France de la Transition Energétique.
            </p>
          </div>
        </Carousel.Item>
        <Carousel.Item>
          <div className="post-tablet bg-white">
            <p className="h6-m text-primary post-date-tablet">21 June 2022</p>
            <Carousel
              controls={false}
              indicators={true}
              interval={null}
              slide={true}
              activeIndex={indexPost}
              onSelect={handleSelectPost}
              className="post-image-tablet"
            >
              <Carousel.Item>
                <img src={img10} className="car-img-zoom-tablet" />
              </Carousel.Item>
              <Carousel.Item>
                <img src={img11} className="car-img-zoom-tablet" />
              </Carousel.Item>
            </Carousel>
            <p className="s3-m post-title-tablet">Innotrophées 2022</p>
            <p className="text-lg-m post-description-tablet">
              We are delighted to inform you that our start-up is awarded at
              13th edition Innotrophées, Grenoble
            </p>
          </div>
        </Carousel.Item>
        <Carousel.Item>
          <div className="post-tablet bg-white">
            <p className="h6-m text-primary post-date-tablet">14 June 2022</p>
            <Carousel
              controls={false}
              indicators={false}
              interval={null}
              slide={true}
              activeIndex={indexPost}
              onSelect={handleSelectPost}
              className="post-image-tablet"
            >
              <Carousel.Item>
                <img src={img12} className="car-img-tablet" />
              </Carousel.Item>
            </Carousel>
            <p className="s3-m post-title-tablet">
              Simulation and Digital Technologies 2022
            </p>
            <p className="text-lg-m post-description-tablet">
              Grand Public Award at Simulation and Digital Technologies
              competition.
            </p>
          </div>
        </Carousel.Item>
      </Carousel>
    </div>
  );
}
