import './hero.css';
import image from '../assets/hero_image.jpg';

export default function Hero() {
  return (
    <div className='hero-products-power-desktop bg-primary'>
      <img
        src={image}
        alt='Hero'
        className='image-hero-products-power-desktop'
      />
      <div className='text-box-hero-products-power-desktop text-white'>
        <h2 className='h2'>Power Grid Products</h2>
        <p className='text-lg text-hero-products-power-desktop'>
          Based on real-world experiments and proof of concepts conducted over
          the years, we provide products for global, online, and continuous
          power grid surveillance. Our solutions exploit early fault indicators,
          leading to significantly improved predictive maintenance.
        </p>
      </div>
    </div>
  );
}
