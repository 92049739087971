import "./classification.css";
import classification_power from "../assets/classification_power.svg";
import classification_water_gas from "../assets/classification_water_gas.svg";

export default function Classification() {
  return (
    <div className="general-approach-classification-desktop">
      <h2 className="h2 text-primary">Classification</h2>
      <div className="classification-joint-content-desktop">
        <ul className="classification-list-desktop">
          <li className="text-lg">
            Quantum-inspired representations of waveforms pave the way for a
            powerful and insightful characterization of invisible patterns, not
            observable in other representation spaces.
          </li>
          <li className="text-lg">
            More than 10 years of physically inferred data allow us to create
            robust classifications for identifying all types of patterns.
          </li>
        </ul>
      </div>
      <div className="classification-content-desktop">
        <div className="classification-figure-desktop">
          <h5 className="s4">Classification for Power Networks</h5>
          <img
            src={classification_power}
            className="classification-figure-image-desktop"
          />
        </div>
        <div className="localization-element-divider-desktop" />
        <div className="classification-figure-desktop">
          <h5 className="s4">Classification for Water/Gas Pipes</h5>
          <img
            src={classification_water_gas}
            className="classification-figure-image-desktop"
          />
        </div>
      </div>
    </div>
  );
}
