import "./classification.css";
import classification_power from "../assets/classification_power.svg";
import classification_water_gas from "../assets/classification_water_gas.svg";

export default function Classification() {
  return (
    <div className="general-approach-classification-tablet">
      <h2 className="h3-m text-primary">Classification</h2>
      <div className="classification-joint-content-tablet">
        <ul className="classification-list-tablet">
          <li className="text-lg-m">
            Quantum-inspired representations of waveforms pave the way for a
            powerful and insightful characterization of invisible patterns, not
            observable in other representation spaces.
          </li>
          <li className="text-lg-m">
            More than 10 years of physically inferred data allow us to create
            robust classifications for identifying all types of patterns.
          </li>
        </ul>
      </div>
      <div className="classification-content-container-tablet">
        <div className="classification-content-tablet">
          <div className="classification-content-title-tablet">
            <h3 className="s4-m">Classification for Power Networks</h3>
            <div className="classification-element-divider-tablet" />
          </div>
          <img
            src={classification_power}
            className="classification-figure-image-tablet"
          />
        </div>
        <div className="classification-content-tablet">
          <div className="classification-content-title-tablet">
            <h3 className="s4-m">Classification for Water/Gas Pipes</h3>
            <div className="classification-element-divider-tablet" />
          </div>
          <img
            src={classification_water_gas}
            className="classification-figure-image-tablet"
          />
        </div>
      </div>
    </div>
  );
}
