import "./hero.css";
import image from "../assets/hero_image_mobile.jpg";

export default function Hero() {
  return (
    <div className="hero-actis-mobile bg-primary">
      <img src={image} alt="Hero" className="image-hero-actis-mobile" />
      <div className="text-box-hero-actis-mobile text-white">
        <h2 className="h4-s">Actis (Active Sensing)</h2>
        <p className="text-reg-s text-hero-actis-mobile">
          An innovative method for locating pre-faults, local anomalies, and
          disturbances in both power networks and water/gas pipelines.
        </p>
      </div>
    </div>
  );
}
