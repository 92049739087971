import LegalMentionsDesktop from "./desktop/LegalMentionsDesktop";
import LegalMentionsTablet from "./tablet/LegalMentionsTablet";
import LegalMentionsMobile from "./mobile/LegalMentionsMobile";
import { useMediaQuery } from "react-responsive";

export default function ProductsWaterGas() {
  const isMobileDevice = useMediaQuery({
    query: "(min-width: 320px)",
  });

  const isTabletDevice = useMediaQuery({
    query: "(min-width: 768px)",
  });

  const isDesktopDevice = useMediaQuery({
    query: "(min-width: 1024px)",
  });

  return (
    <div>
      {isDesktopDevice ? (
        <LegalMentionsDesktop />
      ) : isTabletDevice ? (
        <LegalMentionsTablet />
      ) : (
        <LegalMentionsMobile />
      )}
    </div>
  );
}
