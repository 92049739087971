import "./nav_bar.css";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import logo from "../assets/Altrans_Logo_White.svg";
import menu from "../assets/menu_button.svg";

export default function NavBar(props) {
  return (
    <Navbar expand={false} className="navbar-mobile navbar-dark">
      <Navbar.Brand href="/">
        <img src={logo} className="navbar-logo-img-mobile" />
      </Navbar.Brand>
      <Navbar.Toggle
        aria-controls="basic-navbar-nav"
        className="navbar-menu-button-cont-mobile"
      >
        <img src={menu} className="navbar-menu-button-mobile" />
      </Navbar.Toggle>
      <Navbar.Collapse id="basic-navbar-nav">
        <Nav className="navbar-menu-mobile">
          <NavDropdown
            className={
              "h6-s dropdown-mobile navbar-dropdown-item-mobile " +
              (props.innovation && " navbar-item-active-mobile")
            }
            title="Innovation"
          >
            <NavDropdown.Item
              className="h6-s dropdown-items-mobile"
              href="/general-approach"
            >
              General Approach
            </NavDropdown.Item>
            <NavDropdown.Item
              className="h6-s dropdown-items-mobile"
              href="/translocator"
            >
              Translocator
            </NavDropdown.Item>
            <NavDropdown.Item
              className="h6-s dropdown-items-mobile"
              href="/actis"
            >
              Actis
            </NavDropdown.Item>
          </NavDropdown>
          <NavDropdown
            className={
              "h6-s dropdown-mobile navbar-dropdown-item-mobile " +
              (props.products && " navbar-item-active-mobile")
            }
            title="Products"
          >
            <NavDropdown.Item
              className="h6-s dropdown-items-mobile"
              href="/products/power-grids"
            >
              Products for Power Grids
            </NavDropdown.Item>
            <NavDropdown.Item
              className="h6-s dropdown-items-mobile"
              href="/products/water-gas-networks"
            >
              Products for Water/Gas <br /> Grids
            </NavDropdown.Item>
          </NavDropdown>
          <Nav.Link
            className={
              "h6-s text-white navbar-item-mobile " +
              (props.about && " navbar-item-active-mobile")
            }
            href="/about"
          >
            About Us
          </Nav.Link>
          <Nav.Link
            className={
              "h6-s text-white navbar-item-mobile " +
              (props.contact && " navbar-item-active-mobile")
            }
            href="/index"
          >
            Contact
          </Nav.Link>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
}
