import './translocator.css';
import arrow_right from '../assets/arrow_right_light_blue.svg';
import video from '../assets/gif_translocator.mp4';

export default function Translocator() {
  return (
    <div className='translocator-tablet'>
      <h2 className='s2-m'>
        Our Patented Innovation{' '}
        <span className='h3-m text-primary'>Translocator©</span>
      </h2>
      <div className='text-lg-m text-translocator-tablet'>
        <p>
          It consists of several units of transient signal sensors arranged on
          the network and which allow the automatic localization of fault
          sources without the need for synchronization, thanks to the
          exploitation of the signal deformation due to propagation{' '}
          <span className='s4-m'>(TRANSLOCATOR© technology)</span>.
        </p>
      </div>
      <video
        src={video}
        type='video/mp4'
        autoPlay
        muted
        playsinline
        loop
        className='translocator-gif-tablet'
      />
      <div className='text-lg-m text-translocator-tablet'>
        <p>
          A quantum – inspired artificial intelligence module is implemented at
          the level of a group of sensors for the classification of fault
          sources and to extend the diagnosis by the classification of faults.{' '}
          <span className='s4-m'>
            The software and the AI-driven early detections are the heart of our
            innovation.
          </span>
        </p>
      </div>
      <a className='read-more-tablet' href='/translocator'>
        <div className='text-primary text-read-more-tablet'>
          <p className='h5-m no-line-height-tablet'>Read More</p>
          <img src={arrow_right} className='arrow-read-more-tablet' />
        </div>
        <div className='line-read-more-tablet'></div>
      </a>
    </div>
  );
}
