import ProductsWaterGasDesktop from './desktop/ProductsWaterGasDesktop';
import ProductsWaterGasTablet from './tablet/ProductsWaterGasTablet';
import ProductsWaterGasMobile from './mobile/ProductsWaterGasMobile';
import { useMediaQuery } from 'react-responsive';

export default function ProductsWaterGas() {
  const isMobileDevice = useMediaQuery({
    query: '(min-width: 320px)',
  });

  const isTabletDevice = useMediaQuery({
    query: '(min-width: 768px)',
  });

  const isDesktopDevice = useMediaQuery({
    query: '(min-width: 1024px)',
  });

  return (
    <div>
      {isDesktopDevice ? (
        <ProductsWaterGasDesktop />
      ) : isTabletDevice ? (
        <ProductsWaterGasTablet />
      ) : (
        <ProductsWaterGasMobile />
      )}
    </div>
  );
}
