import "./hero.css";
import image from "../assets/hero_image.jpg";

export default function Hero() {
  return (
    <div className="hero-actis-desktop bg-primary">
      <img src={image} alt="Hero" className="image-hero-actis-desktop" />
      <div className="text-box-hero-actis-desktop text-white">
        <h2 className="h2">Actis (Active Sensing)</h2>
        <p className="text-lg text-hero-actis-desktop">
          An innovative method for locating pre-faults, local anomalies, and
          disturbances in both power networks and water/gas pipelines.
        </p>
      </div>
    </div>
  );
}
