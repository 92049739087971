import './step_2.css';
import figure_5 from '../assets/PD_pahse_diagram.mp4';

export default function Step2() {
  return (
    <div className='step-2-desktop'>
      <div className='figure-5-desktop'>
        <video
          src={figure_5}
          type='video/mp4'
          autoPlay
          muted
          playsinline
          loop
          className='video-figure-5-desktop'
        />
        <p className='description-figure-5-desktop s5 text-gray-80'>
          Figure 5 - Phase diagram representation of the PD
        </p>
      </div>
      <div className='step-2-text-desktop bg-primary text-white'>
        <div className='step-2-title-desktop'>
          <p className='h3'>2.</p>
          <p className='h4'>
            Phase Diagram reconstruction based on the amplitudes of detected
            pulse
          </p>
        </div>
        <p className='text-lg step-2-text-content-desktop'>
          Using this quantum-inspired representation, we can highlight the
          presence of the PD, enabling better separation of signal components.
          As illustrated in <span className='s4'>Figure 5</span>, noise gathers
          around the same area in this space, while the PD is represented by a
          circular trajectory.
        </p>
      </div>
    </div>
  );
}
