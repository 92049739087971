import './hero.css';
import image from '../assets/hero_image_tablet.jpg';

export default function Hero() {
  return (
    <div className='hero-about-tablet bg-primary'>
      <img src={image} alt='Hero' className='image-hero-about-tablet' />
      <div className='text-box-hero-about-tablet text-white'>
        <h2 className='h3-m'>About Us</h2>
      </div>
    </div>
  );
}
