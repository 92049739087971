import "./results.css";
import { useState } from "react";
import Auth from "./Auth";
import Graphic from "./Graphic";

export default function Results() {
  const [password, setPassword] = useState("");
  const secretCode = "Oiken2024!";

  return (
    <div className="results-container">
      <h2 className="h2 primary">Continuous leak monitoring</h2>
      {password !== secretCode ? (
        <div>
          <Auth stateChanger={setPassword} />
          {password !== "" && (
            <div className="invalid-result text-lg text-danger">
              Invalid secret code.
            </div>
          )}
        </div>
      ) : (
        <div>
          <Graphic />
        </div>
      )}
    </div>
  );
}
