import './use_cases.css';
import use_case_electric from '../assets/use_case_electric.png';
import use_case_water_gas from '../assets/use_case_water_gas.png';
import arrow_right from '../assets/arrow_right_gray.svg';

export default function UseCases() {
  return (
    <div className='use-cases-tablet'>
      <h3 className='h3-m'>Multiple Operational Scenarios </h3>
      <div className='card-use-cases-tablet'>
        <img src={use_case_electric} className='use-case-image-tablet' />
        <div className='text-use-cases-tablet electric-use-case-tablet'>
          <p className='s1-m text-white'>
            For <span className='h2-m'>Power Grids</span>
          </p>
          <a
            className='read-more-tablet more-products-color-tablet'
            href='/products/power-grids'
          >
            <div className='text-read-more-tablet'>
              <p className='h5-m no-line-height-tablet'>See products</p>
              <img src={arrow_right} className='arrow-read-more-tablet ' />
            </div>
            <div className='line-read-more-tablet more-products-color-line-tablet'></div>
          </a>
        </div>
      </div>
      <div className='card-use-cases-tablet'>
        <img src={use_case_water_gas} className='use-case-image-tablet' />
        <div className='text-use-cases-tablet water-gas-use-case-tablet'>
          <p className='s1-m text-white water-gas-text-tablet'>
            For <span className='h2-m'>Water/Gas Networks</span>
          </p>
          <a
            className='read-more-tablet more-products-color-tablet'
            href='/products/water-gas-networks'
          >
            <div className='text-read-more-tablet'>
              <p className='h5-m no-line-height-tablet'>See products</p>
              <img src={arrow_right} className='arrow-read-more-tablet ' />
            </div>
            <div className='line-read-more-tablet more-products-color-line-tablet'></div>
          </a>
        </div>
      </div>
    </div>
  );
}
