import "./footer.css";
import footer_image from "../assets/footer_tablet.jpg";
import logo from "../assets/Altrans_Logo_White.svg";
import linkedin from "../assets/linkedin.svg";
import youtube from "../assets/youtube.svg";

export default function Footer() {
  return (
    <div className="footer-tablet">
      <img src={footer_image} alt="Footer" className="footer-img-tablet" />
      <div className="footer-content-tablet">
        <div className="logo-and-contact-tablet">
          <img src={logo} className="footer-logo-img-tablet" />
          <div className="footer-connect-tablet text-white">
            <h6 className="h6-m m-0">Connect With Us</h6>
            <div className="footer-email-phone-tablet">
              <p className="s5-m m-0">cornel.ioana@altransinnov.com</p>
              <p className="s5-m m-0">+33 (0)6 32 35 23 71</p>
            </div>
            <div className="social-media-logos-tablet">
              <a href={"https://www.linkedin.com/company/altransinnov/"}>
                <img src={linkedin} className="social-media-logo-tablet" />
              </a>
              <a>
                <img src={youtube} className="social-media-logo-tablet" />
              </a>
            </div>
          </div>
        </div>
        <div className="footer-menu-tablet">
          <div className="footer-menu-column-tablet">
            <a className="text-white s5-m" href="/">
              Home
            </a>
            <a className="text-white s5-m" href="/about">
              About Us
            </a>
            <a className="text-white s5-m" href="/index">
              Contact
            </a>
            <a className="text-white s5-m" href="/legal-mentions">
              Legal Mentions
            </a>
          </div>
          <div className="footer-menu-column-tablet">
            <a className="text-white s5-m" href="/general-approach">
              General Approach
            </a>
            <a className="text-white s5-m" href="/translocator">
              Translocator
            </a>
            <a className="text-white s5-m" href="/actis">
              ACTIS
            </a>
          </div>
          <div className="footer-menu-column-tablet">
            <a className="text-white s5-m" href="/products/power-grids">
              Products for Power Grids
            </a>
            <a className="text-white s5-m" href="/products/water-gas-networks">
              Products for Water/Gas Networks
            </a>
          </div>
        </div>
      </div>
      <p className="text-xs-m footer-copyright-tablet">
        Copyright © 2024 Altrans Energies. All rights reserved.
      </p>
    </div>
  );
}
