import "./hero.css";
import image from "../assets/hero_image_mobile.jpg";

export default function Hero() {
  return (
    <div className="hero-contact-mobile bg-primary">
      <img src={image} alt="Hero" className="image-hero-contact-mobile" />
      <div className="text-box-hero-contact-mobile text-white">
        <h2 className="h4-s">Contact Us</h2>
        <p className="text-reg-s text-hero-contact-mobile">
          Feel free to reach out to us directly via the provided email or phone
          number. We look forward to connecting with you and addressing your
          inquiries promptly.
        </p>
      </div>
    </div>
  );
}
