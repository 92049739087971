import './step_3.css';
import figure_6 from '../assets/localization.mp4';

export default function Step3() {
  return (
    <div className='step-3-desktop'>
      <div className='step-3-text-desktop bg-primary text-white'>
        <div className='step-3-title-desktop'>
          <p className='h3'>3.</p>
          <p className='h4'>Localization of Partial discharge’s source</p>
        </div>
        <div className='text-lg step-3-text-content-desktop'>
          <p>
            Precise localization is achieved by calculating the ratio between
            the relative deformations captured at two sensing points, as
            depicted in <span className='s4'>Figure 6</span>. These sensing
            points typically correspond to the two ends of the monitored
            segment.
          </p>
          <p>
            Our innovative method leverages a principle from physics well-known
            in the energy sector: As a pulse propagates, it undergoes more
            deformation.
          </p>
          <p>
            Consequently, the ratio of relative deformations between two PDs
            will be proportional to the ratio between their respective ranges,
            L1 and L2.
          </p>
        </div>
      </div>
      <div className='figure-6-desktop'>
        <video
          src={figure_6}
          type='video/mp4'
          autoPlay
          muted
          playsinline
          loop
          className='video-figure-6-desktop'
        />
        <p className='description-figure-6-desktop s5 text-gray-80'>
          Figure 6 - Localization of a PD source
        </p>
      </div>
    </div>
  );
}
