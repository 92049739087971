import "./contact.css";
import { useNavigate } from "react-router-dom";

export default function Contact() {
  let navigate = useNavigate();
  const routeChange = () => {
    let path = `/index`;
    navigate(path);
  };

  return (
    <div className="contact-products-power-desktop">
      <p className="h5">Product can be adapted to our client needs.</p>
      <p className="s4">Contact us for more details.</p>
      <button
        className="btn btn-secondary text-primary h5 button-products-power-desktop"
        onClick={routeChange}
      >
        Contact Us
      </button>
    </div>
  );
}
