import "./nav_bar.css";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import logo from "../assets/Altrans_Logo_White.svg";

export default function NavBar(props) {
  return (
    <Navbar expand={true} className="navbar-desktop navbar-dark">
      <Navbar.Brand href="/">
        <img src={logo} className="navbar-logo-img-desktop" />
      </Navbar.Brand>
      <Nav className="navbar-menu-desktop">
        <NavDropdown
          className={
            "h5 dropdown-desktop navbar-dropdown-item-desktop " +
            (props.innovation && " navbar-item-active-desktop")
          }
          title="Innovation"
        >
          <NavDropdown.Item
            className="h5 dropdown-items-desktop"
            href="/general-approach"
          >
            General Approach
          </NavDropdown.Item>
          <NavDropdown.Item
            className="h5 dropdown-items-desktop"
            href="/translocator"
          >
            Translocator
          </NavDropdown.Item>
          <NavDropdown.Item className="h5 dropdown-items-desktop" href="/actis">
            Actis
          </NavDropdown.Item>
        </NavDropdown>
        <NavDropdown
          className={
            "h5 dropdown-desktop navbar-dropdown-item-desktop " +
            (props.products && " navbar-item-active-desktop")
          }
          title="Products"
        >
          <NavDropdown.Item
            className="h5 dropdown-items-desktop"
            href="/products/power-grids"
          >
            Products for Power Grids
          </NavDropdown.Item>
          <NavDropdown.Item
            className="h5 dropdown-items-desktop"
            href="/products/water-gas-networks"
          >
            Products for Water/Gas Grids
          </NavDropdown.Item>
        </NavDropdown>
        <Nav.Link
          className={
            "h5 text-white navbar-item-desktop " +
            (props.about && " navbar-item-active-desktop")
          }
          href="/about"
        >
          About Us
        </Nav.Link>
        <Nav.Link
          className={
            "h5 text-white navbar-item-desktop " +
            (props.contact && " navbar-item-active-desktop")
          }
          href="/index"
        >
          Contact
        </Nav.Link>
      </Nav>
    </Navbar>
  );
}
