import "./hero.css";
import image from "../assets/hero_image.jpg";

export default function Hero() {
  return (
    <div className="hero-contact-desktop bg-primary">
      <img src={image} alt="Hero" className="image-hero-contact-desktop" />
      <div className="text-box-hero-contact-desktop text-white">
        <h2 className="h2">Contact Us</h2>
        <p className="text-lg text-hero-contact-desktop">
          Feel free to reach out to us directly via the provided email or phone
          number. We look forward to connecting with you and addressing your
          inquiries promptly.
        </p>
      </div>
    </div>
  );
}
