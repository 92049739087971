import './hero.css';
import image from '../assets/hero_image.jpg';

export default function Hero() {
  return (
    <div className='hero-products-water-gas-desktop bg-primary'>
      <img
        src={image}
        alt='Hero'
        className='image-hero-products-water-gas-desktop'
      />
      <div className='text-box-hero-products-water-gas-desktop text-white'>
        <h2 className='h2'>Water/Gas networks Products</h2>
        <p className='text-lg text-hero-products-water-gas-desktop'>
          Based on the innovative ACTIS concept, we propose the following
          products for the surveillance of water (or any fluid) or gas pipe
          systems, enabling the detection of leaks along with early warning
          indicators.
        </p>
      </div>
    </div>
  );
}
