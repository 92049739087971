import './why_part_2.css';
import figure_2 from '../assets/partial_discharge.mp4';

export default function WhyPart1() {
  return (
    <div className='why-part-2-tablet'>
      <div className='content-part-2-tablet'>
        <p className='text-lg-m'>
          As an example, in <span className='s4-m'>Figure 2</span>, a fault in
          the power cable isolation may result in the occurrence of partial
          discharges, with each discharge generating a pulse with a duration, or
          width, generally shorter than 1 microsecond.
        </p>
        <div className='figure-2-tablet'>
          <video
            src={figure_2}
            type='video/mp4'
            autoPlay
            muted
            playsinline
            loop
            className='video-figure-2-tablet'
          />
          <p className='description-figure-2-tablet s5-m text-gray-80'>
            Figure 2 - Partial discharge occurance in a medium voltage cable
          </p>
        </div>
        <p className='text-lg-m'>
          The process leading from the initiation of a partial discharge (PD) to
          the final breakdown involves several stages. Below is a brief
          overview:
        </p>
        <ol className='list-figure-2-tablet'>
          <li className='text-lg-m'>
            Partial discharge begins when there are localized defects or
            irregularities in the insulation of electrical equipment, such as
            cables, transformers, or switchgear.
          </li>
          <li className='text-lg-m'>
            High voltage stress on the insulation, combined with the presence of
            defects, leads to ionization of the insulating material.
          </li>
          <li className='text-lg-m'>
            Partial discharge events are often repetitive and occur
            intermittently, releasing energy in the form of electrical
            discharges.
          </li>
          <li className='text-lg-m'>
            Each discharge event contributes to the degradation of the
            insulation.
          </li>
          <li className='text-lg-m'>
            Over time, the frequency and severity of partial discharge events
            may increase, indicating a worsening condition of the insulation.
          </li>
          <li className='text-lg-m'>
            The final breakdown occurs when the insulation can no longer
            withstand the electrical stress, leading to a complete breakdown of
            the insulation barrier.
          </li>
          <li className='text-lg-m'>
            This breakdown results in a complete and sustained electrical
            discharge, causing a fault or failure in the equipment.
          </li>
        </ol>
      </div>
    </div>
  );
}
