import './table.css';

export default function Table() {
  return (
    <div className='technical-features-products-power-tablet'>
      <h4 className='h5-m'>Technical features</h4>

      <div className='table-products-power-tablet'>
        <div className='section-table-products-power-tablet'>
          <div className='section-name-table-products-power-tablet blue-50'>
            <p className='s4-m'>Equipment Source</p>
          </div>
          <div className='row-container-table-products-power-tablet'>
            <div className='row-table-products-power-tablet blue-50'>
              <p className='text-lg-m'>EU certified of all parts</p>
            </div>
          </div>
        </div>
        <div className='section-table-products-power-tablet'>
          <div className='section-name-table-products-power-tablet grey-50'>
            <p className='s4-m'>Sensor parameters</p>
          </div>
          <div className='row-container-table-products-power-tablet'>
            <div className='row-table-products-power-tablet grey-50'>
              <p className='text-lg-m'>Bandwidth: from 0 to 100MHz</p>
            </div>
            <div className='row-table-products-power-tablet grey-25'>
              <p className='text-lg-m'>Sampling frequency: up to 500MHz</p>
            </div>
            <div className='row-table-products-power-tablet grey-50'>
              <p className='text-lg-m'>Sync resolution: ≥ 14 bits</p>
            </div>
            <div className='row-table-products-power-tablet grey-25'>
              <p className='text-lg-m'>
                Technologies: electromagnetic - antenna
              </p>
            </div>
          </div>
        </div>
        <div className='section-table-products-power-tablet'>
          <div className='section-name-table-products-power-tablet blue-50'>
            <p className='s4-m'>Number of detection channels</p>
          </div>
          <div className='row-container-table-products-power-tablet'>
            <div className='row-table-products-power-tablet blue-50'>
              <p className='text-lg-m'>
                Number of channels that can be detected simultaneously ≥4
              </p>
            </div>
          </div>
        </div>
        <div className='section-table-products-power-tablet'>
          <div className='section-name-table-products-power-tablet grey-50'>
            <p className='s4-m'>Software operation</p>
          </div>
          <div className='row-container-table-products-power-tablet'>
            <div className='row-table-products-power-tablet grey-50'>
              <p className='text-lg-m'>Transient signal detection</p>
            </div>
            <div className='row-table-products-power-tablet grey-25'>
              <p className='text-lg-m'>
                A data collection interval can be set as needed to store data in
                the local device
              </p>
            </div>
            <div className='row-table-products-power-tablet grey-50'>
              <p className='text-lg-m'>
                AI-based classification and synchronization-free localization of
                pre faults
              </p>
            </div>
            <div className='row-table-products-power-tablet grey-25'>
              <p className='text-lg-m'>
                Displaying detection status, including alarm
              </p>
            </div>
            <div className='row-table-products-power-tablet grey-50'>
              <p className='text-lg-m'>Normal/Moderate/High Risk</p>
            </div>
            <div className='row-table-products-power-tablet grey-25'>
              <p className='text-lg-m'>
                Red, green and gray indicator light functions
              </p>
            </div>
          </div>
        </div>
        <div className='section-table-products-power-tablet'>
          <div className='section-name-table-products-power-tablet blue-50'>
            <p className='s4-m'>Reports</p>
          </div>
          <div className='row-container-table-products-power-tablet'>
            <div className='row-table-products-power-tablet blue-50'>
              <p className='text-lg-m'>Regular (daily/weekly/monthly)</p>
            </div>
            <div className='row-table-products-power-tablet blue-25'>
              <p className='text-lg-m'>
                Reports about the status of the network
              </p>
            </div>
          </div>
        </div>
        <div className='section-table-products-power-tablet'>
          <div className='section-name-table-products-power-tablet grey-50'>
            <p className='s4-m'>Power level</p>
          </div>
          <div className='row-container-table-products-power-tablet'>
            <div className='row-table-products-power-tablet grey-50'>
              <p className='text-lg-m'>AC110V/220V</p>
            </div>
            <div className='row-table-products-power-tablet grey-25'>
              <p className='text-lg-m'>DC12kV/24V</p>
            </div>
          </div>
        </div>
        <div className='section-table-products-power-tablet'>
          <div className='section-name-table-products-power-tablet blue-50'>
            <p className='s4-m'>Equipment weight</p>
          </div>
          <div className='row-container-table-products-power-tablet'>
            <div className='row-table-products-power-tablet blue-50'>
              <p className='text-lg-m'> &lt;15kg </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
