import './step_3.css';
import figure_6 from '../assets/localization.mp4';

export default function Step3() {
  return (
    <div className='step-3-mobile'>
      <div className='step-3-text-mobile bg-primary text-white'>
        <div className='step-3-title-mobile'>
          <p className='h5-s'>3.</p>
          <p className='h6-s'>Localization of Partial discharge’s source</p>
        </div>
        <p className='text-reg-s step-3-text-content-mobile'>
          <p>
            Precise localization is achieved by calculating the ratio between
            the relative deformations captured at two sensing points, as
            depicted in <span className='s5-s'>Figure 6</span>. These sensing
            points typically correspond to the two ends of the monitored
            segment.
          </p>
          <p>
            Our innovative method leverages a principle from physics well-known
            in the energy sector: As a pulse propagates, it undergoes more
            deformation.
          </p>
          <p>
            Consequently, the ratio of relative deformations between two PDs
            will be proportional to the ratio between their respective ranges,
            L1 and L2.
          </p>
        </p>
      </div>
      <div className='figure-6-mobile'>
        <video
          src={figure_6}
          type='video/mp4'
          autoPlay
          muted
          playsinline
          loop
          className='video-figure-6-mobile'
        />
        <p className='description-figure-6-mobile s6-s text-gray-80'>
          Figure 6 - Localization of a PD source
        </p>
      </div>
    </div>
  );
}
