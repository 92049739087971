import NavBar from "../../components/mobile/NavBar";
import Footer from "../../components/mobile/Footer";
import Hero from "./Hero";
import Detection from "./Detection";
import Localization from "./Localization";
import Classification from "./Classification";
import RiskAssessment from "./RiskAssessment";

export default function GeneralApproachMobile() {
  return (
    <div>
      <NavBar innovation />
      <Hero />
      <Detection />
      <Localization />
      <Classification />
      <RiskAssessment />
      <Footer />
    </div>
  );
}
