import './hero.css';
import image from '../assets/hero_image_mobile.jpg';

export default function Hero() {
  return (
    <div className='hero-products-power-mobile bg-primary'>
      <img
        src={image}
        alt='Hero'
        className='image-hero-products-power-mobile'
      />
      <div className='text-box-hero-products-power-mobile text-white'>
        <h2 className='h4-s'>Power Grid Products</h2>
        <p className='text-reg-s text-hero-products-power-mobile'>
          Based on real-world experiments and proof of concepts conducted over
          the years, we provide products for global, online, and continuous
          power grid surveillance. Our solutions exploit early fault indicators,
          leading to significantly improved predictive maintenance.
        </p>
      </div>
    </div>
  );
}
