import "./footer.css";
import footer_image from "../assets/footer_mobile.jpg";
import logo from "../assets/Altrans_Logo_White.svg";
import linkedin from "../assets/linkedin.svg";
import youtube from "../assets/youtube.svg";

export default function Footer() {
  return (
    <div className="footer-mobile">
      <img src={footer_image} alt="Footer" className="footer-img-mobile" />
      <div className="footer-content-mobile">
        <img src={logo} className="footer-logo-img-mobile" />
        <div className="footer-middle-mobile">
          <div className="footer-connect-mobile text-white">
            <h6 className="h6-s m-0">Connect With Us</h6>
            <div className="footer-email-phone-mobile">
              <p className="s5-s m-0">cornel.ioana@altransinnov.com</p>
              <p className="s5-s m-0">+33 (0)6 32 35 23 71</p>
            </div>
            <div className="social-media-logos-mobile">
              <a href={"https://www.linkedin.com/company/altransinnov/"}>
                <img src={linkedin} className="social-media-logo-mobile" />
              </a>
              <a>
                <img src={youtube} className="social-media-logo-mobile" />
              </a>
            </div>
          </div>
          <div className="footer-menu-mobile">
            <div className="footer-menu-column-mobile">
              <a className="text-white s5-s" href="/">
                Home
              </a>
              <a className="text-white s5-s" href="/about">
                About Us
              </a>
              <a className="text-white s5-s" href="/index">
                Contact
              </a>
              <a className="text-white s5-s" href="/legal-mentions">
                Legal Mentions
              </a>
            </div>
            <div className="footer-menu-column-mobile">
              <a className="text-white s5-s" href="/general-approach">
                General Approach
              </a>
              <a className="text-white s5-s" href="/translocator">
                Translocator
              </a>
              <a className="text-white s5-s" href="/actis">
                ACTIS
              </a>
            </div>
          </div>
          <div className="footer-menu-column-mobile">
            <a className="text-white s5-s" href="/products/power-grids">
              Products for Power Grids
            </a>
            <a className="text-white s5-s" href="/products/water-gas-networks">
              Products for Water/Gas Networks
            </a>
          </div>
        </div>
      </div>
      <p className="text-xs-s footer-copyright-mobile">
        Copyright © 2024 Altrans Energies. All rights reserved.
      </p>
    </div>
  );
}
