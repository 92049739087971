import React, { useState, useEffect } from "react";
import axios from "axios";
import * as XLSX from "xlsx";
import Highcharts, { setOptions } from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { type } from "@testing-library/user-event/dist/type";

export default function Graphic() {
  const [xlsxData, setXlsxData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [options, setOptions] = useState(null);

  useEffect(() => {
    const fetchXlsxData = async () => {
      try {
        const url =
          "https://oiken2024.s3.eu-north-1.amazonaws.com/Leaks_localization.xlsx";
        const response = await axios.get(url, {
          responseType: "arraybuffer",
          headers: {
            "Cache-Control": "no-cache",
          },
        });
        const data = parseXlsxData(response.data);
        console.log(data);
        setXlsxData(data);
        setLoading(false);
      } catch (error) {
        setError(error.message);
        setLoading(false);
      }
    };

    fetchXlsxData();
  }, []);

  const parseXlsxData = (excelData) => {
    const workbook = XLSX.read(excelData, { type: "array" });
    const sheetName = workbook.SheetNames[0];
    const worksheet = workbook.Sheets[sheetName];
    const data = XLSX.utils.sheet_to_json(worksheet, { header: 1 });

    data.forEach((row) => {
      for (let i = 0; i < row.length; i++) {
        if (!row[i]) row[i] = null;
      }
    });

    return data;
  };

  useEffect(() => {
    if (xlsxData) {
      const data = [];
      const level = [];

      xlsxData.forEach((xlsxRow) => {
        if (xlsxRow[0].includes("Location")) {
          const newSerie = {
            name: xlsxRow[0],
            data: xlsxRow.slice(1),
          };
          data.push(newSerie);
        } else if (xlsxRow[0].includes("Level")) {
          level.push(xlsxRow.slice(1));
        }
      });

      /*for (let i = 1; i < xlsxData.length; i + 2) {
        console.log(i);

        const row = xlsxData[i];
        const dataForRow = [];
        for (let j = 1; j < row.length; j++) {
          const newPointData = {
            x: row[j],
            level: xlsxData[i + 1][j],
          };
          dataForRow.push(newPointData);
        }

        const newSerie = {
          name: row[0],
          data: dataForRow,
        };
        data.push(newSerie);
      }*/

      if (data.length > 0) {
        setOptions({
          chart: {
            type: "line",
          },
          plotOptions: {
            line: {
              marker: {
                symbol: "circle",
                radius: 3, // Set the radius (size) of the dots
              },
            },
          },
          chart: {
            marginTop: 21, // Add your desired padding value here
            // Additional chart configurations...
          },
          title: {
            text: null, // or text: ''
          },
          xAxis: {
            categories: xlsxData[0].slice(1),
          },
          yAxis: {
            title: {
              text: "Range [m]",
            },
            min: 0,
            max: 1300,
            tickPositions: [
              0, 100, 200, 300, 400, 500, 600, 700, 800, 900, 1000, 1100, 1200,
              1300,
            ],
            plotLines: [
              {
                value: 0,
                color: "gray",
                dashStyle: "dash",
                width: 1,
                label: {
                  text: "Node 1",
                  align: "center",
                  style: {
                    fontSize: "12px",
                    color: "gray",
                    fontWeight: "bold",
                  },
                },
              },
              {
                value: 1300,
                color: "gray",
                dashStyle: "dash",
                width: 1,
                label: {
                  text: "Node 2",
                  align: "center",
                  style: {
                    fontSize: "12px",
                    color: "gray",
                    fontWeight: "bold",
                  },
                },
              },
            ],
          },
          series: data,
          tooltip: {
            formatter: function () {
              var index = this.point.index;
              var seriesIndex = this.series.index;
              var seriesName =
                '<span style="font-weight:bold;font-size:14px;color:' +
                this.series.color +
                ';">&#9679; ' +
                this.series.name +
                "</span>";
              return (
                seriesName +
                "&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;" +
                "<br/><b>Date:</b> " +
                this.x +
                "<br/><b>Location:</b> " +
                this.y +
                "<br/><b>Level:</b> " +
                level[seriesIndex][index].toFixed(3)
              );
            },
          },
        });
      }
    }
  }, [xlsxData]);

  if (loading) return <div className="text-lg">Loading...</div>;
  if (error) return <div className="text-lg">Error: {error.message}</div>;

  if (xlsxData && options)
    return <HighchartsReact highcharts={Highcharts} options={options} />;

  return <div className="text-lg">No results</div>;
}
