import "./nav_bar.css";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import logo from "../assets/Altrans_Logo_White.svg";
import menu from "../assets/menu_button.svg";

export default function NavBar(props) {
  return (
    <Navbar expand={false} className="navbar-tablet navbar-dark">
      <Navbar.Brand href="/">
        <img src={logo} className="navbar-logo-img-tablet" />
      </Navbar.Brand>
      <Navbar.Toggle
        aria-controls="basic-navbar-nav"
        className="navbar-menu-button-cont-tablet"
      >
        <img src={menu} className="navbar-menu-button-tablet" />
      </Navbar.Toggle>
      <Navbar.Collapse id="basic-navbar-nav">
        <Nav className="navbar-menu-tablet">
          <NavDropdown
            className={
              "h5-m dropdown-tablet navbar-dropdown-item-tablet " +
              (props.innovation && " navbar-item-active-tablet")
            }
            title="Innovation"
          >
            <NavDropdown.Item
              className="h5-m dropdown-items-tablet"
              href="/general-approach"
            >
              General Approach
            </NavDropdown.Item>
            <NavDropdown.Item
              className="h5-m dropdown-items-tablet"
              href="/translocator"
            >
              Translocator
            </NavDropdown.Item>
            <NavDropdown.Item
              className="h5-m dropdown-items-tablet"
              href="/actis"
            >
              Actis
            </NavDropdown.Item>
          </NavDropdown>
          <NavDropdown
            className={
              "h5-m dropdown-tablet navbar-dropdown-item-tablet " +
              (props.products && " navbar-item-active-tablet")
            }
            title="Products"
          >
            <NavDropdown.Item
              className="h5-m dropdown-items-tablet"
              href="/products/power-grids"
            >
              Products for Power Grids
            </NavDropdown.Item>
            <NavDropdown.Item
              className="h5-m dropdown-items-tablet"
              href="/products/water-gas-networks"
            >
              Products for Water/Gas Grids
            </NavDropdown.Item>
          </NavDropdown>
          <Nav.Link
            className={
              "h5-m text-white navbar-item-tablet " +
              (props.about && " navbar-item-active-tablet")
            }
            href="/about"
          >
            About Us
          </Nav.Link>
          <Nav.Link
            className={
              "h5-m text-white navbar-item-tablet " +
              (props.contact && " navbar-item-active-tablet")
            }
            href="/index"
          >
            Contact
          </Nav.Link>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
}
