import "./why_part_1.css";
import figure_1 from "../assets/figure_1.svg";

export default function WhyPart1() {
  return (
    <div className="why-part-1-tablet">
      <h2 className="h3-m">Why is it useful?</h2>
      <div className="content-part-1-tablet">
        <p className="text-lg-m">
          In transport lines, such as those for electric power, water, or data,
          various phenomena may lead to the generation of transients (pulses) at
          specific points in the lines. It is desirable to detect and locate the
          area in which these pulses are generated.
        </p>
        <p className="text-lg-m">
          The most important phenomena that serve as sources of transient
          signals are partial discharges, electrical arcs, and impedance
          mismatching. There are various reasons for their occurrence, some of
          which are presented in <span className="s4-m">Figure 1</span>.
        </p>
        <div className="figure-1-tablet">
          <img src={figure_1} className="image-figure-1-tablet" />
          <p className="description-figure-1-tablet s5-m text-gray-80">
            Figure 1 - Examples of cable issues and transient source phenomena
          </p>
        </div>
        <p className="text-lg-m">Here, we include:</p>
        <ul className="list-figure-1-tablet">
          <li className="text-lg-m">
            Mechanical degradations resulting from careless cable handling,
            especially during installation in the network.
          </li>
          <li className="text-lg-m">
            Aging phenomena that make the infrastructure critical.
          </li>
          <li className="text-lg-m">
            Misuse of the cables, such as overloading their capacity, especially
            in today's high-energy-demand scenario
          </li>
          <li className="text-lg-m">
            Manufacturing imperfections, encompassing problems that the cables
            may have even before being put into service, rendering them more
            susceptible to faults.
          </li>
        </ul>
      </div>
    </div>
  );
}
