import './support.css';
import logo1 from '../assets/logo_1.png';
import logo2 from '../assets/logo_2.png';
import logo3 from '../assets/logo_3.png';
import logo4 from '../assets/logo_4.png';
import logo5 from '../assets/logo_5.png';
import logo6 from '../assets/logo_6.png';

export default function Support() {
  return (
    <div className='support-tablet'>
      <div className='support-title-tablet'>
        <h3 className='h4-m'>With the support of</h3>
        <div className='support-divider-tablet' />
      </div>
      <div className='logos-container-tablet-1'>
        <img src={logo1} className='logo-image-1-tablet' />
        <img src={logo3} className='logo-image-3-tablet' />
        <img src={logo5} className='logo-image-5-tablet' />
      </div>
      <div className='logos-container-tablet-2'>
        <img src={logo2} className='logo-image-2-tablet' />
        <img src={logo4} className='logo-image-4-tablet' />
        <img src={logo6} className='logo-image-6-tablet' />
      </div>
    </div>
  );
}
