import './table.css';

export default function Table() {
  return (
    <div className='technical-features-products-water-gas-tablet'>
      <h4 className='h5-m'>Technical features</h4>

      <div className='table-products-water-gas-tablet'>
        <div className='section-table-products-water-gas-tablet'>
          <div className='section-name-table-products-water-gas-tablet blue-50'>
            <p className='s4-m'>Equipment Source</p>
          </div>
          <div className='row-container-table-products-water-gas-tablet'>
            <div className='row-table-products-water-gas-tablet blue-50'>
              <p className='text-lg-m'>EU certified of all parts</p>
            </div>
          </div>
        </div>
        <div className='section-table-products-water-gas-tablet'>
          <div className='section-name-table-products-water-gas-tablet grey-50'>
            <p className='s4-m'>Acoustic sensor parameters</p>
          </div>
          <div className='row-container-table-products-water-gas-tablet'>
            <div className='row-table-products-water-gas-tablet grey-50'>
              <p className='text-lg-m'>Bandwidth: from 0 to 100MHz</p>
            </div>
            <div className='row-table-products-water-gas-tablet grey-25'>
              <p className='text-lg-m'>Sampling frequency: up to 10MHz</p>
            </div>
            <div className='row-table-products-water-gas-tablet grey-50'>
              <p className='text-lg-m'>Sync resolution: ≥ 14 bits</p>
            </div>
          </div>
        </div>
        <div className='section-table-products-water-gas-tablet'>
          <div className='section-name-table-products-water-gas-tablet blue-50'>
            <p className='s4-m'>Electromagnetic sensor parameters</p>
          </div>
          <div className='row-container-table-products-water-gas-tablet'>
            <div className='row-table-products-water-gas-tablet blue-50'>
              <p className='text-lg-m'>Bandwidth: from 0 to 100MHz</p>
            </div>
            <div className='row-table-products-water-gas-tablet blue-25'>
              <p className='text-lg-m'>Sampling frequency: up to 500MHz</p>
            </div>
            <div className='row-table-products-water-gas-tablet blue-50'>
              <p className='text-lg-m'>Sync resolution: ≥ 14 bits</p>
            </div>
          </div>
        </div>
        <div className='section-table-products-water-gas-tablet'>
          <div className='section-name-table-products-water-gas-tablet grey-50'>
            <p className='s4-m'>Software operation</p>
          </div>
          <div className='row-container-table-products-water-gas-tablet'>
            <div className='row-table-products-water-gas-tablet grey-50'>
              <p className='text-lg-m'>Transient signal detection</p>
            </div>
            <div className='row-table-products-water-gas-tablet grey-25'>
              <p className='text-lg-m'>Leak estimation</p>
            </div>
            <div className='row-table-products-water-gas-tablet grey-50'>
              <p className='text-lg-m'>Velocity estimation</p>
            </div>
            <div className='row-table-products-water-gas-tablet grey-25'>
              <p className='text-lg-m'>
                {' '}
                A data collection interval can be set as needed to store data in
                the local device
              </p>
            </div>
            <div className='row-table-products-water-gas-tablet grey-50'>
              <p className='text-lg-m'>
                Localization of leaks through the relative deformation of
                detected transients
              </p>
            </div>
            <div className='row-table-products-water-gas-tablet grey-25'>
              <p className='text-lg-m'>
                Displaying detection status, including alarm
              </p>
            </div>
            <div className='row-table-products-water-gas-tablet grey-50'>
              <p className='text-lg-m'>Normal/Moderate/High Risk</p>
            </div>
            <div className='row-table-products-water-gas-tablet grey-25'>
              <p className='text-lg-m'>
                Red, green and gray indicator light functions
              </p>
            </div>
          </div>
        </div>
        <div className='section-table-products-water-gas-tablet'>
          <div className='section-name-table-products-water-gas-tablet blue-50'>
            <p className='s4-m'>Reports</p>
          </div>
          <div className='row-container-table-products-water-gas-tablet'>
            <div className='row-table-products-water-gas-tablet blue-50'>
              <p className='text-lg-m'>Regular (daily/weekly/monthly)</p>
            </div>
            <div className='row-table-products-water-gas-tablet blue-25'>
              <p className='text-lg-m'>
                Reports about the status of the pipe system{' '}
              </p>
            </div>
          </div>
        </div>
        <div className='section-table-products-water-gas-tablet'>
          <div className='section-name-table-products-water-gas-tablet grey-50'>
            <p className='s4-m'>Power level</p>
          </div>
          <div className='row-container-table-products-water-gas-tablet'>
            <div className='row-table-products-water-gas-tablet grey-50'>
              <p className='text-lg-m'>AC110V/220V</p>
            </div>
            <div className='row-table-products-water-gas-tablet grey-25'>
              <p className='text-lg-m'>DC12kV/24V</p>
            </div>
          </div>
        </div>
        <div className='section-table-products-water-gas-tablet'>
          <div className='section-name-table-products-water-gas-tablet blue-50'>
            <p className='s4-m'>Waterproof</p>
          </div>
          <div className='row-container-table-products-water-gas-tablet'>
            <div className='row-table-products-water-gas-tablet blue-50'>
              <p className='text-lg-m'> IP68 </p>
            </div>
          </div>
        </div>
        <div className='section-table-products-water-gas-tablet'>
          <div className='section-name-table-products-water-gas-tablet grey-50'>
            <p className='s4-m'>Equipment weight</p>
          </div>
          <div className='row-container-table-products-water-gas-tablet'>
            <div className='row-table-products-water-gas-tablet grey-50'>
              <p className='text-lg-m'> &lt;15kg </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
