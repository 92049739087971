import React, { useEffect } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { createBrowserHistory } from "history";
import Homepage from "./pages/homepage/Homepage";
import Contact from "./pages/contact/Contact";
import About from "./pages/about/About";
import Translocator from "./pages/translocator/Translocator";
import ProductsPower from "./pages/products_power/ProductsPower";
import ProductsWaterGas from "./pages/products_water_gas/ProductsWaterGas";
import GeneralApproach from "./pages/general_approach/GeneralApproach";
import Actis from "./pages/actis/Actis";
import LegalMentions from "./pages/legal_mentions/LegalMentions";
import Results from "./pages/results/Results";

import ReactGA from "react-ga4";
const TRACKING_ID = "G-9BL43WQR3D";
ReactGA.initialize(TRACKING_ID);

function App() {
  const history = createBrowserHistory();

  history.listen((_) => {
    window.scrollTo(0, 0);
  });

  return (
    <BrowserRouter history={history}>
      <Routes>
        <Route path="/" element={<Homepage />} />
        <Route path="index" element={<Contact />} />
        <Route path="about" element={<About />} />
        <Route path="translocator" element={<Translocator />} />
        <Route path="products/power-grids" element={<ProductsPower />} />
        <Route
          path="products/water-gas-networks"
          element={<ProductsWaterGas />}
        />
        <Route path="general-approach" element={<GeneralApproach />} />
        <Route path="actis" element={<Actis />} />
        <Route path="legal-mentions" element={<LegalMentions />} />
        <Route path="results/oiken-2024" element={<Results />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
