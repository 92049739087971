import './products.css';
import gui from '../assets/gui.png';
import product from '../assets/product.png';

export default function Products() {
  return (
    <div className='products-section-power-desktop'>
      <div className='products-description-power-desktop'>
        <p className='text-lg'>
          Considering the type of monitored network (LV/MV/HV), the products
          are:
        </p>
        <div className='product-description-power-desktop'>
          <h3 className='h3'>TransElec HT/MT</h3>
          <p className='text-lg'>
            The system comprises two units (nodes) of transient signal sensors
            placed on the network, connected to a central data processing unit
            where AI algorithms run. At this level, transient analysis is based
            on the Translocator patent, enabling automatic synchronization-free
            localization of sources and their classification.
          </p>
        </div>
        <div className='product-description-power-desktop'>
          <h3 className='h3'>TransElec BT</h3>
          <p className='text-lg'>
            Designed for monitoring low voltage (LV) equipment, our innovation
            addresses the challenge of detecting partial LV discharges, which
            are not easily visible at long sensing ranges due to the relatively
            low voltage. This is achieved through the implementation of the
            following concept using RODECIN© software: a waveform is emitted
            and, as it propagates through the LV network, it interacts with the
            transients caused by faults. Characterizing this interaction enables
            the system to locate the sources of faults.
          </p>
        </div>
      </div>
      <div className='products-images-power-desktop'>
        <div className='product-image-power-desktop'>
          <img src={product} className='image-power-desktop' />
          <p className='description-image-power-desktop s5 text-gray-80'>
            Packaging of Local Sensing Unit
          </p>
        </div>
        <div className='product-image-power-desktop'>
          <img src={gui} className='image-power-desktop' />
          <p className='description-image-power-desktop s5 text-gray-80'>
            Monitoring GUI
          </p>
        </div>
      </div>
    </div>
  );
}
