import "./auth.css";
import { useState } from "react";
import visibility from "./assets/visibility.svg";

export default function Auth({ stateChanger, ...rest }) {
  const [inputValue, setInputValue] = useState("");
  const [showPassword, setShowPassword] = useState(false);

  const handleChange = (e) => {
    setInputValue(e.target.value);
  };

  const handleClick = () => {
    stateChanger(inputValue);
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      handleClick();
    }
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (
    <div className="auth-container">
      <p className="text-lg label-text-auth">Enter your secret code below:</p>
      <div className="auth-password-container">
        <input
          type={showPassword ? "text" : "password"}
          value={inputValue}
          onChange={handleChange}
          onKeyDown={handleKeyPress}
          placeholder="Code"
          className="text-lg secret-code-input"
        />
        <button
          className="auth-visibility-button"
          onClick={togglePasswordVisibility}
        >
          <img src={visibility} className="auth-visibility-icon" />
        </button>
        <button
          className="btn btn-primary text-white h5 secret-code-button"
          onClick={handleClick}
        >
          Submit
        </button>
      </div>
    </div>
  );
}
