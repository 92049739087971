import ProductsPowerDesktop from './desktop/ProductsPowerDesktop';
import ProductsPowerTablet from './tablet/ProductsPowerTablet';
import ProductsPowerMobile from './mobile/ProductsPowerMobile';
import { useMediaQuery } from 'react-responsive';

export default function ProductsPower() {
  const isMobileDevice = useMediaQuery({
    query: '(min-width: 320px)',
  });

  const isTabletDevice = useMediaQuery({
    query: '(min-width: 768px)',
  });

  const isDesktopDevice = useMediaQuery({
    query: '(min-width: 1024px)',
  });

  return (
    <div>
      {isDesktopDevice ? (
        <ProductsPowerDesktop />
      ) : isTabletDevice ? (
        <ProductsPowerTablet />
      ) : (
        <ProductsPowerMobile />
      )}
    </div>
  );
}
