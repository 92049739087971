import "./hero.css";
import image from "../assets/hero_image_mobile.jpg";

export default function Hero() {
  return (
    <div className="hero-legal-mentions-mobile bg-primary">
      <img
        src={image}
        alt="Hero"
        className="image-hero-legal-mentions-mobile"
      />
      <div className="text-box-hero-legal-mentions-mobile text-white">
        <h2 className="h4-s">Legal Mentions</h2>
      </div>
    </div>
  );
}
