import "./why_part_3.css";
import figure_3 from "../assets/translocator_formula.png";

export default function WhyPart1() {
  return (
    <div className="why-part-3-tablet">
      <div className="content-part-3-tablet">
        <p className="text-lg-m">
          An example of transient source localization is depicted in{" "}
          <span className="s4-m">Figure 3</span>. Locating the pulse source
          allows for the identification of the fault, facilitating the
          replacement or repair of the cable section affected. Errors in
          estimating the exact position of the pulse source may lead to taking
          action on the wrong cable portion and necessitate repeating the
          maintenance operation.
        </p>
        <div className="figure-3-tablet">
          <img src={figure_3} className="image-figure-3-tablet" />
          <p className="description-figure-3-tablet s5-m text-gray-80">
            Figure 3 - Localization of the transient source using TRANSLOCATOR
            patent
          </p>
        </div>
      </div>
      <h2 className="h3-m">Steps</h2>
    </div>
  );
}
