import "./risk_assessment.css";
import risk_assessment from "../assets/risk_assessment.svg";

export default function RiskAssessment() {
  return (
    <div className="general-approach-risk-assessment-tablet">
      <h2 className="h3-m text-primary">Risk Assessment</h2>
      <div className="risk-assessment-joint-content-tablet">
        <ul className="risk-assessment-list-tablet">
          <li className="text-lg-m">
            Real-time and continuous monitoring enables us to generate recurrent
            reports about the status of the monitored system.
          </li>
          <li className="text-lg-m">
            Alerts are tailored to client and system conditions.
          </li>
        </ul>
      </div>
      <div className="risk-assessment-content-tablet">
        <div className="risk-assessment-content-title-tablet">
          <h3 className="s4-m">Risk Assessment Graphic</h3>
          <div className="risk-assessment-element-divider-tablet" />
        </div>
        <img
          src={risk_assessment}
          className="risk-assessment-figure-image-tablet"
        />
      </div>
    </div>
  );
}
