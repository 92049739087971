import "./footer.css";
import footer_image from "../assets/Footer.jpg";
import logo from "../assets/Altrans_Logo_White.svg";
import linkedin from "../assets/linkedin.svg";
import youtube from "../assets/youtube.svg";

export default function Footer() {
  return (
    <div className="footer-desktop">
      <img src={footer_image} alt="Footer" className="footer-img-desktop" />
      <div className="footer-content-desktop">
        <img src={logo} className="footer-logo-img-desktop" />
        <div className="footer-menu-desktop">
          <div className="footer-menu-column-desktop">
            <a className="text-white s5" href="/">
              Home
            </a>
            <a className="text-white s5" href="/about">
              About Us
            </a>
            <a className="text-white s5" href="/index">
              Contact
            </a>
            <a className="text-white s5" href="/legal-mentions">
              Legal Mentions
            </a>
          </div>
          <div className="footer-menu-column-desktop">
            <a className="text-white s5" href="/general-approach">
              General Approach
            </a>
            <a className="text-white s5" href="/translocator">
              Translocator
            </a>
            <a className="text-white s5" href="/actis">
              ACTIS
            </a>
          </div>
          <div className="footer-menu-column-desktop">
            <a className="text-white s5" href="/products/power-grids">
              Products for Power Grids
            </a>
            <a className="text-white s5" href="/products/water-gas-networks">
              Products for Water/Gas Networks
            </a>
          </div>
        </div>
        <div className="footer-connect-desktop text-white">
          <h6 className="h6 m-0">Connect With Us</h6>
          <div className="footer-email-phone-desktop">
            <p className="s5 m-0">cornel.ioana@altransinnov.com</p>
            <p className="s5 m-0">+33 (0)6 32 35 23 71</p>
          </div>
          <div className="social-media-logos-desktop">
            <a href={"https://www.linkedin.com/company/altransinnov/"}>
              <img src={linkedin} className="social-media-logo-desktop" />
            </a>
            <a>
              <img src={youtube} className="social-media-logo-desktop" />
            </a>
          </div>
        </div>
      </div>
      <p className="text-xs footer-copyright-desktop">
        Copyright © 2024 Altrans Energies. All rights reserved.
      </p>
    </div>
  );
}
