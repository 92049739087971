import "./risk_assessment.css";
import risk_assessment from "../assets/risk_assessment.svg";

export default function RiskAssessment() {
  return (
    <div className="general-approach-risk-assessment-desktop">
      <h2 className="h2 text-primary">Risk Assessment</h2>
      <div className="risk-assessment-joint-content-desktop">
        <ul className="risk-assessment-list-desktop">
          <li className="text-lg">
            Real-time and continuous monitoring enables us to generate recurrent
            reports about the status of the monitored system.
          </li>
          <li className="text-lg">
            Alerts are tailored to client and system conditions.
          </li>
        </ul>
      </div>
      <div className="risk-assessment-figure-desktop">
        <h5 className="s4">Risk Assessment Graphic</h5>
        <img
          src={risk_assessment}
          className="risk-assessment-figure-image-desktop"
        />
      </div>
    </div>
  );
}
