import './why_part_2.css';
import figure_2 from '../assets/partial_discharge.mp4';

export default function WhyPart2() {
  return (
    <div className='why-part-2-desktop'>
      <div className='content-part-2-desktop'>
        <p className='text-lg'>
          As an example, in <span className='s4'>Figure 2</span>, a fault in the
          power cable isolation may result in the occurrence of partial
          discharges, with each discharge generating a pulse with a duration, or
          width, generally shorter than 1 microsecond.
        </p>
        <div className='content-figure-2-desktop'>
          <div className='figure-2-desktop'>
            <video
              src={figure_2}
              type='video/mp4'
              autoPlay
              muted
              playsinline
              loop
              className='video-figure-2-desktop'
            />
            <p className='description-figure-2-desktop s5 text-gray-80'>
              Figure 2 - Partial discharge occurance in a medium voltage cable
            </p>
          </div>
          <div className='text-figure-2-desktop'>
            <p className='text-lg'>
              The process leading from the initiation of a partial discharge
              (PD) to the final breakdown involves several stages. Below is a
              brief overview:
            </p>
            <ol className='list-figure-2-desktop'>
              <li className='text-lg'>
                Partial discharge begins when there are localized defects or
                irregularities in the insulation of electrical equipment, such
                as cables, transformers, or switchgear.
              </li>
              <li className='text-lg'>
                High voltage stress on the insulation, combined with the
                presence of defects, leads to ionization of the insulating
                material.
              </li>
              <li className='text-lg'>
                Partial discharge events are often repetitive and occur
                intermittently, releasing energy in the form of electrical
                discharges.
              </li>
              <li className='text-lg'>
                Each discharge event contributes to the degradation of the
                insulation.
              </li>
              <li className='text-lg'>
                Over time, the frequency and severity of partial discharge
                events may increase, indicating a worsening condition of the
                insulation.
              </li>
              <li className='text-lg'>
                The final breakdown occurs when the insulation can no longer
                withstand the electrical stress, leading to a complete breakdown
                of the insulation barrier.
              </li>
              <li className='text-lg'>
                This breakdown results in a complete and sustained electrical
                discharge, causing a fault or failure in the equipment.
              </li>
            </ol>
          </div>
        </div>
      </div>
    </div>
  );
}
