import "./hero.css";
import { useNavigate } from "react-router-dom";
import image from "../assets/hero_image_tablet.jpg";

export default function Hero() {
  let navigate = useNavigate();
  const routeChange = () => {
    let path = `/index`;
    navigate(path);
  };

  return (
    <div className="hero-tablet bg-primary">
      <img src={image} alt="Hero" className="image-hero-tablet" />
      <div className="text-box-hero-tablet text-white">
        <div className="title-box-hero-tablet">
          <h2 className="h3-m title-hero-tablet">
            Empowering tomorrow's energy grids with online and precise early
            fault detection
          </h2>
          <p className="s3-m">
            for <span className="h4-m">Electrical</span>,{" "}
            <span className="h4-m">Gas</span>, and{" "}
            <span className="h4-m">Water</span> Infrastructure Monitoring
          </p>
        </div>
        <p className="text-lg-m text-hero-tablet">
          <span className="h5-m">Altrans Energies</span> pioneers advanced
          solutions in global and predictive monitoring seamlessly integrating
          innovative sensing and quantum-inspired AI algorithms to enhance the
          resilience of grids and to reduce their maintenance cost.
        </p>
        <button
          className="btn btn-secondary text-primary h5-m button-hero-tablet"
          onClick={routeChange}
        >
          Contact Us
        </button>
      </div>
    </div>
  );
}
