import './downloads.css';
import { Link } from 'react-router-dom';
import download from '../assets/download.svg';

export default function Downloads() {
  return (
    <div className='downloads-section-desktop'>
      <p className='text-lg'>
        You can also check out two detailed proofs of concept (POCs) in both
        laboratory and real-world contexts that demonstrate the capabilities of
        our technology.
      </p>
      <div className='download-links-desktop'>
        <Link
          to='/files/Experimental_Translocator_POC.pdf'
          target='_blank'
          download
          className='download-link-desktop'
        >
          <img src={download} className='download-icon-desktop' />
          <p className='s4'>Experimental_Translocator_POC.pdf</p>
        </Link>
        <Link
          to='/files/Real_World_Translocator_POC.pdf'
          target='_blank'
          download
          className='download-link-desktop'
        >
          <img src={download} className='download-icon-desktop' />
          <p className='s4'>Real_World_Translocator_POC.pdf</p>
        </Link>
      </div>
    </div>
  );
}
