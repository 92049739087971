import NavBar from "../../components/mobile/NavBar";
import Footer from "../../components/mobile/Footer";
import Hero from "./Hero";
import AboutText from "./AboutText";
import Awards from "./Awards";
import Team from "./Team";

export default function AboutMobile() {
  return (
    <div>
      <NavBar about />
      <Hero />
      <AboutText />
      <Awards />
      <Team />
      <Footer />
    </div>
  );
}
