import './commitment.css';
import bullseye from '../assets/bullseye.svg';
import clock from '../assets/clock.svg';
import exclamation from '../assets/exclamation.svg';
import repeat from '../assets/repeat.svg';
import ruler from '../assets/ruler.svg';

export default function Commitment() {
  return (
    <div className='commitment-products-power-desktop'>
      <h4 className='h4'>
        Our commitment for your cable infrastructure surveillance:
      </h4>
      <div className='benefits-list-products-power-desktop'>
        <div className='benefit-products-power-desktop'>
          <img src={repeat} className='image-benefit-ptoducts-power-desktop' />
          <p className='s4'>
            Continuous monitoring - autonomous signal detection,
            synchronization-free localization and classification
          </p>
        </div>
        <div className='benefit-products-power-desktop'>
          <img
            src={bullseye}
            className='image-benefit-ptoducts-power-desktop'
          />
          <p className='s4'>Global coverage - localization around 1 m</p>
        </div>
        <div className='benefit-products-power-desktop'>
          <img src={ruler} className='image-benefit-ptoducts-power-desktop' />
          <p className='s4'>Independent of cable’s type and length</p>
        </div>
        <div className='benefit-products-power-desktop'>
          <img
            src={exclamation}
            className='image-benefit-ptoducts-power-desktop'
          />
          <p className='s4'>Identification of early weaknesses in cables</p>
        </div>
        <div className='benefit-products-power-desktop'>
          <img src={clock} className='image-benefit-ptoducts-power-desktop' />
          <p className='s4'>Real-time risk assessment</p>
        </div>
      </div>
    </div>
  );
}
