import './awards.css';
import image from '../assets/awards_collage.png';

export default function Awards() {
  return (
    <div className='awards-mobile'>
      <div className='bg-primary text-white awards-content-mobile'>
        <div className='awards-text-mobile'>
          <h2 className='h4-s'>Awards</h2>
          <ul className='awards-list-mobile'>
            <li className='text-reg-s awards-list-item-mobile'>
              Winner of the <span className='h6-s'>Paris Urban Lab 2023</span>{' '}
              program.
            </li>
            <li className='text-reg-s awards-list-item-mobile'>
              Labeled <span className='h6-s'>Greentech Innovation 2022</span> by
              the Ministry of Ecological Transition, recognizing our commitment
              to reducing the carbon footprint of electrical network maintenance
              operations.
            </li>
            <li className='text-reg-s awards-list-item-mobile'>
              Winner of the <span className='h6-s'>NETVA 2022</span> program -
              France-United States Collaboration organized by BPI and the
              Embassy of France in the United States.
            </li>
            <li className='text-reg-s awards-list-item-mobile'>
              Public Grand Prize in the{' '}
              <span className='h6-s'>
                "Trophées de la Simulation et des technologies numériques 2022"
              </span>{' '}
              competition organized by L’Usine Nouvelle.
            </li>
            <li className='text-reg-s awards-list-item-mobile'>
              Winner of the <span className='h6-s'>Innotrophées 2022</span>{' '}
              Award organized by the CCI of Grenoble, start-up category.
            </li>
            <li className='text-reg-s awards-list-item-mobile'>
              Winner of the <span className='h6-s'>I-lab 2021</span> with the
              GOLTEN project.
            </li>
            <li className='text-reg-s awards-list-item-mobile'>
              Labeled <span className='h6-s'>DeepTech in November 2020</span>.
            </li>
            <li className='text-reg-s awards-list-item-mobile'>
              Winner of the{' '}
              <span className='h6-s'>Grid’Up Prize by ENEDIS in 2017</span>.
            </li>
          </ul>
        </div>
        <img src={image} className='awards-image-mobile' />
      </div>
    </div>
  );
}
