import './commitment.css';
import bullseye from '../assets/bullseye.svg';
import clock from '../assets/clock.svg';
import exclamation from '../assets/exclamation.svg';
import repeat from '../assets/repeat.svg';
import speed from '../assets/speed.svg';

export default function Commitment() {
  return (
    <div className='commitment-products-water-gas-tablet'>
      <h4 className='h5-m'>
        Our commitment for your cable infrastructure surveillance:
      </h4>
      <div className='benefits-list-products-water-gas-tablet'>
        <div className='benefit-products-water-gas-tablet'>
          <img
            src={repeat}
            className='image-benefit-ptoducts-water-gas-tablet'
          />
          <p className='s4-m'>
            Continuous monitoring - autonomous signal detection
          </p>
        </div>
        <div className='benefit-products-water-gas-tablet'>
          <img
            src={bullseye}
            className='image-benefit-ptoducts-water-gas-tablet'
          />
          <p className='s4-m'>Leak localization and estimation</p>
        </div>
        <div className='benefit-products-water-gas-tablet'>
          <img
            src={speed}
            className='image-benefit-ptoducts-water-gas-tablet'
          />
          <p className='s4-m'>Velocity estimation</p>
        </div>
        <div className='benefit-products-water-gas-tablet'>
          <img
            src={exclamation}
            className='image-benefit-ptoducts-water-gas-tablet'
          />
          <p className='s4-m'>Identification of early weaknesses in pipes</p>
        </div>
        <div className='benefit-products-water-gas-tablet'>
          <img
            src={clock}
            className='image-benefit-ptoducts-water-gas-tablet'
          />
          <p className='s4-m'>Real-time risk assessment</p>
        </div>
      </div>
    </div>
  );
}
