import NavBar from '../../components/desktop/NavBar';
import Footer from '../../components/desktop/Footer';
import Hero from './Hero';
import AboutText from './AboutText';
import Awards from './Awards';
import Team from './Team';

export default function AboutDesktop() {
  return (
    <div>
      <NavBar about />
      <Hero />
      <AboutText />
      <Awards />
      <Team />
      <Footer />
    </div>
  );
}
