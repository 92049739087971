import './translocator.css';
import arrow_right from '../assets/arrow_right_light_blue.svg';
import video from '../assets/gif_translocator.mp4';

export default function Translocator() {
  return (
    <div className='translocator'>
      <div className='text-box-translocator'>
        <h2 className='s2'>
          Our Patented Innovation{' '}
          <span className='h3 text-primary'>Translocator©</span>
        </h2>
        <div className='text-lg text-translocator'>
          <p>
            It consists of several units of transient signal sensors arranged on
            the network and which allow the automatic localization of fault
            sources without the need for synchronization, thanks to the
            exploitation of the signal deformation due to propagation{' '}
            <span className='s4'>(TRANSLOCATOR© technology)</span>.
          </p>
          <p>
            A quantum – inspired artificial intelligence module is implemented
            at the level of a group of sensors for the classification of fault
            sources and to extend the diagnosis by the classification of faults.{' '}
            <span className='s4'>
              The software and the AI-driven early detections are the heart of
              our innovation.
            </span>
          </p>
        </div>
        <a className='read-more' href='/translocator'>
          <div className='text-primary text-read-more'>
            <p className='h5 no-line-height'>Read More</p>
            <img src={arrow_right} className='arrow-read-more' />
          </div>
          <div className='line-read-more'></div>
        </a>
      </div>
      <video
        src={video}
        type='video/mp4'
        autoPlay
        muted
        playsinline
        loop
        className='translocator-gif'
      />
    </div>
  );
}
