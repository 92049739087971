import './about_text.css';

export default function AboutText() {
  return (
    <div className='about-text-desktop'>
      <p className='text-lg'>
        With an impressive{' '}
        <span className='s4'>academic history spanning more than 15 years</span>
        , the startup <span className='h5'>ALTRANS ENERGIES</span> was
        officially established in November 2020 in Grenoble, France.
      </p>
      <p className='text-lg'>
        The genesis of our enterprise is rooted in an extensive portfolio of{' '}
        <span className='s4'>40+ published research papers</span> , complemented
        by <span className='s4'>patented ideas</span> that extend across Europe,
        the U.S.A., and Asia.
      </p>
      <p className='text-lg'>
        At the heart of our journey is a{' '}
        <span className='s4'>
          conscientious team of researchers and engineers
        </span>{' '}
        whose resolute efforts have transformed aspirational goals into tangible
        realities, leading to{' '}
        <span className='s4'>multiple awards and distinctions</span> earned over
        time.
      </p>
    </div>
  );
}
