import "./contact.css";
import { useNavigate } from "react-router-dom";

export default function Contact() {
  let navigate = useNavigate();
  const routeChange = () => {
    let path = `/index`;
    navigate(path);
  };

  return (
    <div className="contact-products-water-gas-tablet">
      <p className="h5-m">Product can be adapted to our client needs.</p>
      <p className="s4-m">Contact us for more details.</p>
      <button
        className="btn btn-secondary text-primary h5-m button-products-water-gas-tablet"
        onClick={routeChange}
      >
        Contact Us
      </button>
    </div>
  );
}
