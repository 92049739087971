import "./hero.css";
import image from "../assets/hero_image_mobile.jpg";

export default function Hero() {
  return (
    <div className="hero-general-approach-mobile bg-primary">
      <img
        src={image}
        alt="Hero"
        className="image-hero-general-approach-mobile"
      />
      <div className="text-box-hero-general-approach-mobile text-white">
        <h2 className="h4-s">General approach</h2>
        <p className="text-reg-s text-hero-general-approach-mobile">
          Our complete solution for monitoring networks and pipelines consists
          of four main steps, which are presented below.
        </p>
      </div>
    </div>
  );
}
