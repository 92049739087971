import Hero from "./Hero";
import Solution from "./Solution";
import Translocator from "./Translocator";
import UseCases from "./UseCases";
import News from "./News";
import Support from "./Support";
import NavBar from "../../components/mobile/NavBar";
import Footer from "../../components/mobile/Footer";

export default function HomepageMobile() {
  return (
    <div>
      <NavBar />
      <Hero />
      <Solution />
      <UseCases />
      <Translocator />
      <News />
      <Support />
      <Footer />
    </div>
  );
}
