import "./detection.css";
import antenna_power_lines from "../assets/antenna_power_lines.png";
import antenna_pipe from "../assets/atenna_pipe.png";
import front_sensing_unit from "../assets/front_sensing_unit.png";
import back_sensing_unit from "../assets/back_sensing_unit.png";

export default function Detection() {
  return (
    <div className="general-approach-detection-mobile">
      <h2 className="h4-s text-primary">Detection</h2>
      <div className="detection-content-mobile">
        <div className="detection-content-title-mobile">
          <h3 className="s5-s">Power Networks</h3>
          <div className="detection-element-divider-mobile" />
        </div>
        <ul className="detection-list-mobile">
          <li className="text-reg-s">
            Based on electromagnetic sensing and advanced signal processing
            algorithms.
          </li>
          <li className="text-reg-s">
            Innovative antenna designs are adapted to different types and sizes
            of cables using an AI configuration module.
          </li>
          <li className="text-reg-s">
            Our signal detection algorithms can identify all types of transient
            phenomena generated by faults, pre-faults indicators, and signals
            from loads or other network accessories and equipment.
          </li>
        </ul>
      </div>
      <div className="detection-content-mobile">
        <div className="detection-content-title-mobile">
          <h3 className="s5-s">Water/Gas Pipes</h3>
          <div className="detection-element-divider-mobile" />
        </div>
        <ul className="detection-list-mobile">
          <li className="text-reg-s">
            Based on acoustic and electromagnetic sensing and advanced signal
            processing algorithms.
          </li>
          <li className="text-reg-s">
            Sensing units are configured differently to adapt to the pipes of
            the system.
          </li>
          <li className="text-reg-s">
            Advanced signal processing methods can detect waveforms that
            indicate interactions with leaks.
          </li>
          <li className="text-reg-s">
            Detection of various patterns generated by phenomena occurring
            inside the pipe (leaks, system control and command signals,
            vibrations, external sources).
          </li>
        </ul>
      </div>
      <div className="detection-images-container-mobile">
        <div className="detection-figure-mobile">
          <img
            src={front_sensing_unit}
            className="detection-figure-image-mobile"
          />
          <p className="detection-figure-description-mobile s6-s text-gray-80">
            Front Packaging of Local Sensing Unit
          </p>
        </div>
        <div className="detection-figure-mobile">
          <img
            src={back_sensing_unit}
            className="detection-figure-image-mobile"
          />
          <p className="detection-figure-description-mobile s6-s text-gray-80">
            Back Packaging of Local Sensing Unit
          </p>
        </div>
        <div className="detection-figure-mobile">
          <img
            src={antenna_power_lines}
            className="detection-figure-image-mobile"
          />
          <p className="detection-figure-description-mobile s6-s text-gray-80">
            Sensing on Power Lines
          </p>
        </div>
        <div className="detection-figure-mobile">
          <img src={antenna_pipe} className="detection-figure-image-mobile" />
          <p className="detection-figure-description-mobile s6-s text-gray-80">
            Sensing on Gas Pipes
          </p>
        </div>
      </div>
    </div>
  );
}
