import './use_cases.css';
import use_case_electric from '../assets/use_case_electric.png';
import use_case_water_gas from '../assets/use_case_water_gas.png';
import arrow_right from '../assets/arrow_right_gray.svg';

export default function UseCases() {
  return (
    <div className='use-cases-mobile'>
      <h3 className='h5-s use-cases-title-mobile'>
        Multiple Operational Scenarios{' '}
      </h3>
      <div className='card-use-cases-mobile'>
        <img src={use_case_electric} className='use-case-image-mobile' />
        <div className='text-use-cases-mobile electric-use-case-mobile'>
          <p className='s3-s text-white'>
            For <span className='h4-s'>Power Grids</span>
          </p>
          <a
            className='read-more-mobile more-products-color-mobile'
            href='/products/power-grids'
          >
            <div className='text-read-more-mobile'>
              <p className='h6-s no-line-height-mobile'>See products</p>
              <img src={arrow_right} className='arrow-read-more-mobile ' />
            </div>
            <div className='line-read-more-mobile more-products-color-line-mobile'></div>
          </a>
        </div>
      </div>
      <div className='card-use-cases-mobile'>
        <img src={use_case_water_gas} className='use-case-image-mobile' />
        <div className='text-use-cases-mobile water-gas-use-case-mobile'>
          <p className='s3-s text-white water-gas-text-mobile'>
            For <span className='h4-s'>Water/Gas Networks</span>
          </p>
          <a
            className='read-more-mobile more-products-color-mobile'
            href='/products/water-gas-networks'
          >
            <div className='text-read-more-mobile'>
              <p className='h6-s no-line-height-mobile'>See products</p>
              <img src={arrow_right} className='arrow-read-more-mobile ' />
            </div>
            <div className='line-read-more-mobile more-products-color-line-mobile'></div>
          </a>
        </div>
      </div>
    </div>
  );
}
