import "./hero.css";
import image from "../assets/hero_image.jpg";

export default function Hero() {
  return (
    <div className="hero-translocator-desktop bg-primary">
      <img src={image} alt="Hero" className="image-hero-translocator-desktop" />
      <div className="text-box-hero-translocator-desktop text-white">
        <h2 className="h2">General approach</h2>
        <p className="text-lg text-hero-translocator-desktop">
          Our complete solution for monitoring networks and pipelines consists
          of four main steps, which are presented below.
        </p>
      </div>
    </div>
  );
}
