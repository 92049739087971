import './products.css';
import gui from '../assets/gui.png';
import product from '../assets/product.png';

export default function Products() {
  return (
    <div className='products-section-water-gas-mobile'>
      <div className='products-description-water-gas-mobile'>
        <p className='text-reg-s'>
          Considering the type of monitored network (water or gas), the products
          are:
        </p>
        <div className='product-description-water-gas-mobile'>
          <h3 className='h5-s'>ALTE-Hydro L/M</h3>
          <p className='text-reg-s'>
            The system comprises several units (nodes) of acoustic and
            electromagnetic signal sensors placed on the pipe, relaying data to
            a central processing unit. At this level, AI algorithms are
            implemented, enabling automatic and accurate detection and
            localization of leaks.
          </p>
        </div>
        <div className='product-description-water-gas-mobile'>
          <h3 className='h5-s'>ALTE-Gas L/M</h3>
          <p className='text-reg-s'>
            The system is designed for monitoring gas and hydrogen pipes. There
            are several fundamental differences between penstocks and
            gas/hydrogen pipes, including not only the materials used for their
            construction but also their overall length and environmental
            conditions (for instance, gas pipes can span miles underwater).
            Despite these differences, the same innovative algorithms are used,
            with a series of custom modifications to compensate for the
            propagation physics.
          </p>
        </div>
        <p className='text-reg-s'>
          The system is modular and adapted for long-range or mid-range
          monitoring, offering both L (long) and M (mid) options. Additionally,
          we can provide monitoring for a single pipe or for up to eight pipes
          simultaneously.
        </p>
      </div>
      <div className='products-images-water-gas-mobile'>
        <div className='product-image-water-gas-mobile'>
          <img src={product} className='image-water-gas-mobile' />
          <p className='description-image-water-gas-mobile s6-s text-gray-80'>
            Packaging of Local Sensing Unit
          </p>
        </div>
        <div className='product-image-water-gas-mobile'>
          <img src={gui} className='image-water-gas-mobile' />
          <p className='description-image-water-gas-mobile s6-s text-gray-80'>
            Monitoring GUI
          </p>
        </div>
      </div>
    </div>
  );
}
