import "./hero.css";
import image from "../assets/hero_image_tablet.jpg";

export default function Hero() {
  return (
    <div className="hero-contact-tablet bg-primary">
      <img src={image} alt="Hero" className="image-hero-contact-tablet" />
      <div className="text-box-hero-contact-tablet text-white">
        <h2 className="h3-m">Contact Us</h2>
        <p className="text-lg-m text-hero-contact-tablet">
          Feel free to reach out to us directly via the provided email or phone
          number. We look forward to connecting with you and addressing your
          inquiries promptly.
        </p>
      </div>
    </div>
  );
}
