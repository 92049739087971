import './about_text.css';

export default function AboutText() {
  return (
    <div className='about-text-tablet'>
      <p className='text-reg-s'>
        With an impressive{' '}
        <span className='s5-s'>
          academic history spanning more than 15 years
        </span>
        , the startup <span className='h6-s'>ALTRANS ENERGIES</span> was
        officially established in November 2020 in Grenoble, France.
      </p>
      <p className='text-reg-s'>
        The genesis of our enterprise is rooted in an extensive portfolio of{' '}
        <span className='s5-s'>40+ published research papers</span> ,
        complemented by <span className='s5-s'>patented ideas</span> that extend
        across Europe, the U.S.A., and Asia.
      </p>
      <p className='text-reg-s'>
        At the heart of our journey is a{' '}
        <span className='s5-s'>
          conscientious team of researchers and engineers
        </span>{' '}
        whose resolute efforts have transformed aspirational goals into tangible
        realities, leading to{' '}
        <span className='s5-s'>multiple awards and distinctions</span> earned
        over time.
      </p>
    </div>
  );
}
