import HomepageDesktop from './desktop/HomepageDesktop';
import HomepageTablet from './tablet/HomepageTablet';
import HomepageMobile from './mobile/HomepageMobile';
import { useMediaQuery } from 'react-responsive';

export default function Homepage() {
  const isMobileDevice = useMediaQuery({
    query: '(min-width: 320px)',
  });

  const isTabletDevice = useMediaQuery({
    query: '(min-width: 768px)',
  });

  const isDesktopDevice = useMediaQuery({
    query: '(min-width: 1024px)',
  });

  return (
    <div>
      {isDesktopDevice ? (
        <HomepageDesktop />
      ) : isTabletDevice ? (
        <HomepageTablet />
      ) : (
        <HomepageMobile />
      )}
    </div>
  );
}
