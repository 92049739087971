import GeneralApproachDesktop from "./desktop/GeneralApproachDesktop";
import GeneralApproachTablet from "./tablet/GeneralApproachTablet";
import GeneralApproachMobile from "./mobile/GeneralApproachMobile";
import { useMediaQuery } from "react-responsive";

export default function GeneralApproach() {
  const isMobileDevice = useMediaQuery({
    query: "(min-width: 320px)",
  });

  const isTabletDevice = useMediaQuery({
    query: "(min-width: 768px)",
  });

  const isDesktopDevice = useMediaQuery({
    query: "(min-width: 1024px)",
  });

  return (
    <div>
      {isDesktopDevice ? (
        <GeneralApproachDesktop />
      ) : isTabletDevice ? (
        <GeneralApproachTablet />
      ) : (
        <GeneralApproachMobile />
      )}
    </div>
  );
}
