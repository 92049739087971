import './hero.css';
import image from '../assets/hero_image.jpg';

export default function Hero() {
  return (
    <div className='hero-translocator-desktop bg-primary'>
      <img src={image} alt='Hero' className='image-hero-translocator-desktop' />
      <div className='text-box-hero-translocator-desktop text-white'>
        <h2 className='h2'>Translocator Patent</h2>
        <p className='text-lg text-hero-translocator-desktop'>
          A method for locating a source of pulses in a dispersive medium using
          at least one pair of sensors.
        </p>
      </div>
    </div>
  );
}
