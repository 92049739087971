import "./content.css";
import email from "../assets/email.svg";
import location from "../assets/location.svg";
import phone from "../assets/phone.svg";

export default function Content() {
  return (
    <div className="contact-info-mobile">
      <div className="contact-element-mobile">
        <img src={email} className="contact-element-image-mobile" />
        <div className="contact-element-divider-mobile" />
        <p className="s4-s">cornel.ioana@altransinnov.com</p>
      </div>
      <div className="contact-element-mobile">
        <img src={phone} className="contact-element-image-mobile" />
        <div className="contact-element-divider-mobile" />
        <p className="s4-s">+33 (0)6 32 35 23 71</p>
      </div>
      <div className="contact-location-element-mobile">
        <img src={location} className="contact-element-image-mobile" />
        <div className="contact-element-divider-mobile" />
        <div className="address-and-location-mobile">
          <div className="address-text-mobile">
            <p className="s4-s">ALTRANS Energies SAS</p>
            <p className="s4-s">21 avenue des Martyrs</p>
            <p className="s4-s">38031</p>
            <p className="s4-s">Grenoble</p>
            <p className="s4-s">France</p>
          </div>
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d2811.220211520183!2d5.7005649!3d45.2028792!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x478af5a879c35903%3A0xd2736938f51cfdc4!2sGrenoble%20INP%20-%20Ense3!5e0!3m2!1sen!2sfr!4v1705596256631!5m2!1sen!2sfr"
            allowfullscreen=""
            loading="lazy"
            referrerpolicy="no-referrer-when-downgrade"
            className="map-location-mobile"
          ></iframe>
        </div>
      </div>
    </div>
  );
}
