import "./risk_assessment.css";
import risk_assessment from "../assets/risk_assessment.svg";

export default function RiskAssessment() {
  return (
    <div className="general-approach-risk-assessment-mobile">
      <h2 className="h4-s text-primary">Risk Assessment</h2>
      <div className="risk-assessment-joint-content-mobile">
        <ul className="risk-assessment-list-mobile">
          <li className="text-reg-s">
            Real-time and continuous monitoring enables us to generate recurrent
            reports about the status of the monitored system.
          </li>
          <li className="text-reg-s">
            Alerts are tailored to client and system conditions.
          </li>
        </ul>
      </div>
      <div className="risk-assessment-content-mobile">
        <div className="risk-assessment-content-title-mobile">
          <h3 className="s5-s">Risk Assessment Graphic</h3>
          <div className="risk-assessment-element-divider-mobile" />
        </div>
        <img
          src={risk_assessment}
          className="risk-assessment-figure-image-mobile"
        />
      </div>
    </div>
  );
}
