import './hero.css';
import image from '../assets/hero_image.jpg';

export default function Hero() {
  return (
    <div className='hero-about-desktop bg-primary'>
      <img src={image} alt='Hero' className='image-hero-about-desktop' />
      <div className='text-box-hero-about-desktop text-white'>
        <h2 className='h2'>About Us</h2>
      </div>
    </div>
  );
}
