import NavBar from "../../components/desktop/NavBar";
import Footer from "../../components/desktop/Footer";
import Hero from "./Hero";
import WhyPart1 from "./WhyPart1";
import WhyPart2 from "./WhyPart2";
import WhyPart3 from "./WhyPart3";
import Step1 from "./Step1";
import Step2 from "./Step2";
import Step3 from "./Step3";
import Downloads from "./Downloads";

export default function TranslocatorDesktop() {
  return (
    <div>
      <NavBar innovation />
      <Hero />
      <WhyPart1 />
      <WhyPart2 />
      <WhyPart3 />
      <Step1 />
      <Step2 />
      <Step3 />
      <Downloads />
      <Footer />
    </div>
  );
}
