import './commitment.css';
import bullseye from '../assets/bullseye.svg';
import clock from '../assets/clock.svg';
import exclamation from '../assets/exclamation.svg';
import repeat from '../assets/repeat.svg';
import ruler from '../assets/ruler.svg';

export default function Commitment() {
  return (
    <div className='commitment-products-power-tablet'>
      <h4 className='h5-m'>
        Our commitment for your cable infrastructure surveillance:
      </h4>
      <div className='benefits-list-products-power-tablet'>
        <div className='benefit-products-power-tablet'>
          <img src={repeat} className='image-benefit-ptoducts-power-tablet' />
          <p className='s4-m'>
            Continuous monitoring - autonomous signal detection,
            synchronization-free localization and classification
          </p>
        </div>
        <div className='benefit-products-power-tablet'>
          <img src={bullseye} className='image-benefit-ptoducts-power-tablet' />
          <p className='s4-m'>Global coverage - localization around 1 m</p>
        </div>
        <div className='benefit-products-power-tablet'>
          <img src={ruler} className='image-benefit-ptoducts-power-tablet' />
          <p className='s4-m'>Independent of cable’s type and length</p>
        </div>
        <div className='benefit-products-power-tablet'>
          <img
            src={exclamation}
            className='image-benefit-ptoducts-power-tablet'
          />
          <p className='s4-m'>Identification of early weaknesses in cables</p>
        </div>
        <div className='benefit-products-power-tablet'>
          <img src={clock} className='image-benefit-ptoducts-power-tablet' />
          <p className='s4-m'>Real-time risk assessment</p>
        </div>
      </div>
    </div>
  );
}
