import "./hero.css";
import image from "../assets/hero_image_tablet.jpg";

export default function Hero() {
  return (
    <div className="hero-legal-mentions-tablet bg-primary">
      <img
        src={image}
        alt="Hero"
        className="image-hero-legal-mentions-tablet"
      />
      <div className="text-box-hero-legal-mentions-tablet text-white">
        <h2 className="h3-m">Legal Mentions</h2>
      </div>
    </div>
  );
}
