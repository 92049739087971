import './step_1.css';
import figure_4 from '../assets/antenna.mp4';

export default function Step1() {
  return (
    <div className='step-1-mobile'>
      <div className='step-1-text-mobile bg-primary text-white'>
        <div className='step-1-title-mobile'>
          <p className='h5-s'>1.</p>
          <p className='h6-s'>
            Pulse detection originating from a Partial Discharge source
          </p>
        </div>
        <p className='text-reg-s step-1-text-content-mobile'>
          The wideband Translocator antenna, as depicted in{' '}
          <span className='s5-s'>Figure 4</span>, is wrapped around the cable.
          It senses the presence of the PD, capturing its waveform as it
          propagates through the cable and reaches the sensing point.
        </p>
      </div>
      <div className='figure-4-mobile'>
        <video
          src={figure_4}
          type='video/mp4'
          autoPlay
          muted
          playsinline
          loop
          className='video-figure-4-mobile'
        />
        <p className='description-figure-4-mobile s6-s text-gray-80'>
          Figure 4 - Electromagnetic sensing of transient phenomena
        </p>
      </div>
    </div>
  );
}
