import TranslocatorDesktop from './desktop/TranslocatorDesktop';
import TranslocatorTablet from './tablet/TranslocatorTablet';
import TranslocatorMobile from './mobile/TranslocatorMobile';
import { useMediaQuery } from 'react-responsive';

export default function Translocator() {
  const isMobileDevice = useMediaQuery({
    query: '(min-width: 320px)',
  });

  const isTabletDevice = useMediaQuery({
    query: '(min-width: 768px)',
  });

  const isDesktopDevice = useMediaQuery({
    query: '(min-width: 1024px)',
  });

  return (
    <div>
      {isDesktopDevice ? (
        <TranslocatorDesktop />
      ) : isTabletDevice ? (
        <TranslocatorTablet />
      ) : (
        <TranslocatorMobile />
      )}
    </div>
  );
}
