import './hero.css';
import image from '../assets/hero_image_tablet.jpg';

export default function Hero() {
  return (
    <div className='hero-products-power-tablet bg-primary'>
      <img
        src={image}
        alt='Hero'
        className='image-hero-products-power-tablet'
      />
      <div className='text-box-hero-products-power-tablet text-white'>
        <h2 className='h3-m'>Power Grid Products</h2>
        <p className='text-lg-m text-hero-products-power-tablet'>
          Based on real-world experiments and proof of concepts conducted over
          the years, we provide products for global, online, and continuous
          power grid surveillance. Our solutions exploit early fault indicators,
          leading to significantly improved predictive maintenance.
        </p>
      </div>
    </div>
  );
}
