import './table.css';

export default function Table() {
  return (
    <div className='technical-features-products-power-desktop'>
      <h4 className='h4'>Technical features</h4>

      <div className='table-products-power-desktop'>
        <div className='section-table-products-power-desktop'>
          <div className='section-name-table-products-power-desktop blue-50'>
            <p className='s4'>Equipment Source</p>
          </div>
          <div className='row-container-table-products-power-desktop'>
            <div className='row-table-products-power-desktop blue-50'>
              <p className='text-lg'>EU certified of all parts</p>
            </div>
          </div>
        </div>
        <div className='section-table-products-power-desktop'>
          <div className='section-name-table-products-power-desktop grey-50'>
            <p className='s4'>Sensor parameters</p>
          </div>
          <div className='row-container-table-products-power-desktop'>
            <div className='row-table-products-power-desktop grey-50'>
              <p className='text-lg'>Bandwidth: from 0 to 100MHz</p>
            </div>
            <div className='row-table-products-power-desktop grey-25'>
              <p className='text-lg'>Sampling frequency: up to 500MHz</p>
            </div>
            <div className='row-table-products-power-desktop grey-50'>
              <p className='text-lg'>Sync resolution: ≥ 14 bits</p>
            </div>
            <div className='row-table-products-power-desktop grey-25'>
              <p className='text-lg'>Technologies: electromagnetic - antenna</p>
            </div>
          </div>
        </div>
        <div className='section-table-products-power-desktop'>
          <div className='section-name-table-products-power-desktop blue-50'>
            <p className='s4'>Number of detection channels</p>
          </div>
          <div className='row-container-table-products-power-desktop'>
            <div className='row-table-products-power-desktop blue-50'>
              <p className='text-lg'>
                Number of channels that can be detected simultaneously ≥4
              </p>
            </div>
          </div>
        </div>
        <div className='section-table-products-power-desktop'>
          <div className='section-name-table-products-power-desktop grey-50'>
            <p className='s4'>Software operation</p>
          </div>
          <div className='row-container-table-products-power-desktop'>
            <div className='row-table-products-power-desktop grey-50'>
              <p className='text-lg'>Transient signal detection</p>
            </div>
            <div className='row-table-products-power-desktop grey-25'>
              <p className='text-lg'>
                A data collection interval can be set as needed to store data in
                the local device
              </p>
            </div>
            <div className='row-table-products-power-desktop grey-50'>
              <p className='text-lg'>
                AI-based classification and synchronization-free localization of
                pre faults
              </p>
            </div>
            <div className='row-table-products-power-desktop grey-25'>
              <p className='text-lg'>
                Displaying detection status, including alarm
              </p>
            </div>
            <div className='row-table-products-power-desktop grey-50'>
              <p className='text-lg'>Normal/Moderate/High Risk</p>
            </div>
            <div className='row-table-products-power-desktop grey-25'>
              <p className='text-lg'>
                Red, green and gray indicator light functions
              </p>
            </div>
          </div>
        </div>
        <div className='section-table-products-power-desktop'>
          <div className='section-name-table-products-power-desktop blue-50'>
            <p className='s4'>Reports</p>
          </div>
          <div className='row-container-table-products-power-desktop'>
            <div className='row-table-products-power-desktop blue-50'>
              <p className='text-lg'>Regular (daily/weekly/monthly)</p>
            </div>
            <div className='row-table-products-power-desktop blue-25'>
              <p className='text-lg'>Reports about the status of the network</p>
            </div>
          </div>
        </div>
        <div className='section-table-products-power-desktop'>
          <div className='section-name-table-products-power-desktop grey-50'>
            <p className='s4'>Power level</p>
          </div>
          <div className='row-container-table-products-power-desktop'>
            <div className='row-table-products-power-desktop grey-50'>
              <p className='text-lg'>AC110V/220V</p>
            </div>
            <div className='row-table-products-power-desktop grey-25'>
              <p className='text-lg'>DC12kV/24V</p>
            </div>
          </div>
        </div>
        <div className='section-table-products-power-desktop'>
          <div className='section-name-table-products-power-desktop blue-50'>
            <p className='s4'>Equipment weight</p>
          </div>
          <div className='row-container-table-products-power-desktop'>
            <div className='row-table-products-power-desktop blue-50'>
              <p className='text-lg'> &lt;15kg </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
